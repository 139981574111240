import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from "reactstrap";
import { FaSync } from 'react-icons/fa'; 

const Refreshable = ({ isRefreshing, onRefresh }) => {
    const refreshIconRef = useRef(null);
    const [isRefReady, setIsRefReady] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        if (refreshIconRef.current) {
            setIsRefReady(true);
        }
    }, []);

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    return (
        <div ref={refreshIconRef} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <FaSync
              size={16}
              style={{ color: isRefreshing ? '#007bff' : '#808080', cursor: isRefreshing ? 'not-allowed' : 'pointer' }}
              onClick={!isRefreshing ? onRefresh : undefined}
            />

            {isRefReady && (
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target={refreshIconRef.current}
                    toggle={toggleTooltip}
                >
                    Refresh data
                </Tooltip>
            )}
        </div>
    );
};

export default Refreshable;
