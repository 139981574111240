import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { hazardTypeMapping, bombTypeMapping } from 'utils/reportTypeMapping';
import { useLocation } from 'react-router-dom';
import { collection, getDocs, query, getDoc, doc } from 'firebase/firestore';
import { Row, Col, Card, FormGroup, Label, CardBody, CardTitle, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { firestore, auth } from '../../firebase';
import ExploreMap from '../maps/ExploreMap';
import ReportsTable from '../tables/ReportsTable';
import DownloadButtonBasicReport from '../components/DownloadButtonBasicReport';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import CustomizableSelect from 'views/components/CustomizableSelect';
import DateRangePickerComponent from 'views/components/DateRangePickerComponent';
import LoadingWrapper from 'views/components/LoadingWrapper';
import Refreshable from 'views/components/Refreshable';
import { geographicRegions } from 'utils/region';
import csvIcon from '../../assets/img/csv.png';
import { useAuthState } from "react-firebase-hooks/auth";
import { CsvDataGenerator } from '../components/CsvDataGenerator';
import { reportNameMapping } from 'utils/mapping';
import { useMembership } from 'hooks/useMembership';
import debounce from 'lodash/debounce';
import { getCachedData, setCachedData } from 'utils/cacheUtils';

const CACHE_KEY = 'cachedReports';

const BasicReport = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [filters, setFilters] = useState({
    selectedRegion: [],
    selectedCountry: [],
    selectedState: [],
    selectedCity: [],
    selectedCategory: [],
    selectedTopic: [],
    selectedInvestigation: [],
    selectedDateRange: {
      startDate: startOfDay(subMonths(new Date(), 1)),
      endDate: endOfDay(new Date()),
    },
    selectedReportTypes: [],
  });
  const [dropdownData, setDropdownData] = useState({
    countries: [],
    states: [],
    cities: [],
    categories: [],
    topics: [],
    investigations: [],
  });
  const [resetMap, setResetMap] = useState(false);
  const [user] = useAuthState(auth);
  const [userMembershipLevel, setUserMembershipLevel] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { isSuperUser, handleRestrictedAction, filterRestrictedReports, modal, toggleModal, modalContent} = useMembership(userMembershipLevel);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (user) {
      const fetchUserMembership = async () => {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserMembershipLevel(userDoc.data().membershipLevel || "basic");
        }
      };
      fetchUserMembership();
    }
  }, [user]);


  const fetchData = useCallback(async (forceRefresh = false) => {
    setIsLoading(true);
    try {
      if (!forceRefresh) {
        const cachedReports = getCachedData(CACHE_KEY);
        if (cachedReports) {
          setReports(cachedReports);
          return;
        }
      }
      const q = query(collection(firestore, 'Reports'));
      const querySnapshot = await getDocs(q);

      const reportsList = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          documentId: doc.id,
          reportId: data.reportId,
          reportName: data.reportName,
          reportType: reportNameMapping[data.reportType] || data.reportType,
          originalReportUserId: data.originalReportUserId,
          reportingApp: data.reportingApp,
          city: data.city,
          state: data.state,
          country: data.country,
          latitude: data.latitude,
          longitude: data.longitude,
          contactMethod: data.contactMethod,
          timeReported: data.timeReported,
          description: data.description,
          originalSocialMediaLink: data.originalSocialMediaLink,
          hazardType: hazardTypeMapping[data.hazardType] || data.hazardType,
          bombType: bombTypeMapping[data.bombType] || data.bombType,
          priority: data.priority,
          equipment : data.equipment,
          size: data.size,
          contaminant: data.contaminant,
          protectiveMeasures: data.protectiveMeasures,
          resourcesThreatened: data.resourcesThreatened,
          unitCountry: data.unitCountry,
          withChemicals: data.withChemicals,
          initialSafetyStatus: data.initialSafetyStatus,
          verifiedSafetyStatus: data.verifiedSafetyStatus,
          // verificationStatus: data.verificationStatus,
          // verificationTimestamp: data.verificationTimestamp,
          // verificationUpVotes: data.verificationUpVotes,
          // verificationDownVotes: data.verificationUpVotes,

        };
      });

      setReports(reportsList);
      setCachedData(CACHE_KEY, reportsList);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setResetMap(false);
    if (location.state) {
      const { country, reportType, region, state, city, topic, investigation, dateRange } = location.state;

      setFilters(prevFilters => ({
        ...prevFilters,
        selectedRegion: region || [],
        selectedCountry: country || [],
        selectedState: state || [],
        selectedCity: city || [],
        selectedCategory: reportType || [],
        selectedTopic: topic || [],
        selectedInvestigation: investigation || [],
        selectedDateRange: {
          startDate: dateRange?.startDate || startOfDay(subMonths(new Date(), 1)),
          endDate: dateRange?.endDate || endOfDay(new Date()),
        },
      }));
      setResetMap(true);
    } else {
      setFilters({
        selectedRegion: [],
        selectedCountry: [],
        selectedState: [],
        selectedCity: [],
        selectedCategory: [],
        selectedTopic: [],
        selectedInvestigation: [],
        selectedDateRange: {
          startDate: startOfDay(subMonths(new Date(), 1)),
          endDate: endOfDay(new Date()),
        },
        selectedReportTypes: [],
      });
      setResetMap(true);
    }
    fetchData();
  }, [location.state, fetchData]);

  const filteredReports = useMemo(() => {
    let filtered = [...reports];
    const {
      selectedRegion,
      selectedCountry,
      selectedState,
      selectedCity,
      selectedCategory,
      selectedTopic,
      selectedInvestigation,
      selectedDateRange,
      selectedReportTypes,
    } = filters;

    if (selectedRegion.length > 0) {
      filtered = filtered.filter(report =>
        selectedRegion.some(region => geographicRegions[region].includes(report.country))
      );
    }

    if (selectedCountry.length > 0) {
      filtered = filtered.filter(report => selectedCountry.includes(report.country));
    }

    if (selectedState.length > 0) {
      filtered = filtered.filter(report => selectedState.includes(report.state));
    }

    if (selectedCity.length > 0) {
      filtered = filtered.filter(report => selectedCity.includes(report.city));
    }

    if (selectedCategory.length > 0) {
      filtered = filtered.filter(report => selectedCategory.includes(report.reportType));
    }

    if (selectedTopic.length > 0) {
      filtered = filtered.filter(report =>
        selectedTopic.includes(report.hazardType) || selectedTopic.includes(report.bombType)
      );
    }

    if (selectedInvestigation.length > 0) {
      filtered = filtered.filter(report => selectedInvestigation.includes(report.investigation));
    }

    filtered = filtered.filter(report => {
      if (report.timeReported && report.timeReported.seconds) {
        const reportDate = new Date(report.timeReported.seconds * 1000);
        return (
          reportDate >= selectedDateRange.startDate && reportDate <= selectedDateRange.endDate
        );
      }
      return false;
    });

    if (selectedReportTypes.length > 0) {
      filtered = filtered.filter(report => selectedReportTypes.includes(report.reportType));
    }
    
    const availableCountries = Array.from(new Set(filtered.map(report => report.country)));
    const availableStates = Array.from(new Set(filtered.map(report => report.state)));
    const availableCities = Array.from(new Set(filtered.map(report => report.city)));
    const availableCategories = Array.from(new Set(filtered.map(report => report.reportType)));
    const availableTopics = Array.from(
      new Set(filtered.map(report => report.hazardType || report.bombType))
    );
    const availableInvestigations = Array.from(new Set(filtered.map(report => report.investigation)));

    setDropdownData({
      countries: availableCountries.map(value => ({ value, label: value })),
      states: availableStates.map(value => ({ value, label: value })),
      cities: availableCities.map(value => ({ value, label: value })),
      categories: availableCategories.map(value => ({ value, label: value })),
      topics: availableTopics.map(value => ({ value, label: value })),
      investigations: availableInvestigations.map(value => ({ value, label: value })),
    });

    return filterRestrictedReports(filtered, ["Military Activity"]);
  }, [reports, filters, filterRestrictedReports]);

  
  const handleFilterChange = (filterType, selectedOptions) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: selectedOptions ? selectedOptions.map(option => option.value) : [],
    }));
  };
  const handleDateRangeSelect = (startDate, endDate) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      selectedDateRange: { startDate, endDate },
    }));
  };

  useEffect(() => {
    const generateCsvData = async () => {
      const data = await CsvDataGenerator({
        reports: filteredReports,
        isBoloReport : false,
        isDetailedReport: false,
      });
      setCsvData(data);
    };
    generateCsvData();
  }, [filteredReports]);
  

    const handleRefresh = debounce(async () => {
      localStorage.removeItem(CACHE_KEY); 
      setIsRefreshing(true);
      await fetchData(true);
      setIsRefreshing(false);
    }, 500);
    
  
    useEffect(() => {
      return () => {
        handleRefresh.cancel(); 
      };
    }, []);
  
    
   if (isLoading) {
      return <LoadingWrapper isLoading={isLoading} />;
    }
    
  return (
    <div>
      <Card style={{ width: '93%', marginTop: '75px', marginLeft: '40px', marginRight: '40px' }}>
        <CardBody>
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <p style={{ fontSize: '10px', margin: 0 }}>
              * By default, the dashboard displays data from the past 30 days.
            </p>
            <Refreshable isRefreshing={isRefreshing} onRefresh={handleRefresh} />
          </div>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label>Geographic Region</Label>
                <CustomizableSelect
                  options={Object.keys(geographicRegions).map(region => ({
                    value: region,
                    label: region,
                  }))}
                  isMulti
                  value={filters.selectedRegion.map(value => ({ value, label: value }))}
                  onChange={options => handleFilterChange('selectedRegion', options)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Country</Label>
                <CustomizableSelect
                  options={dropdownData.countries}
                  isMulti
                  value={filters.selectedCountry.map(value => ({ value, label: value }))}
                  onChange={options => handleFilterChange('selectedCountry', options)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>State/Province</Label>
                <CustomizableSelect
                  options={dropdownData.states}
                  isMulti
                  value={filters.selectedState.map(value => ({ value, label: value }))}
                  onChange={(options) => handleFilterChange('selectedState', options)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>City</Label>
                <CustomizableSelect
                  options={dropdownData.cities}
                  isMulti
                  value={filters.selectedCity.map(value => ({ value, label: value }))}
                  onChange={(options) => handleFilterChange('selectedCity', options)}
                  
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label>Investigation</Label>
                <CustomizableSelect
                  options={dropdownData.investigations}
                  isMulti
                  value={filters.selectedInvestigation.map(value => ({ value, label: value }))}
                  onChange={(options) => handleFilterChange('selectedInvestigation', options)}
                  
                  />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Category</Label>
                <CustomizableSelect
                  options={dropdownData.categories}
                  isMulti
                  value={filters.selectedCategory.map(value => ({ value, label: value }))}
                  onChange={(options) => handleFilterChange('selectedCategory', options)}
                  
                  />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Topic</Label>
                <CustomizableSelect
                  options={dropdownData.topics}
                  isMulti
                  value={filters.selectedTopic.map(value => ({ value, label: value }))}
                  onChange={(options) => handleFilterChange('selectedTopic', options)}
                  
                  />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Date Range</Label>
                <DateRangePickerComponent
                  selectedDateRange={filters.selectedDateRange}
                  onDateRangeSelect={handleDateRangeSelect}
                  defaultStartDate={startOfDay(subMonths(new Date(), 1))}
                  defaultEndDate={endOfDay(new Date())}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div style={{  width: '93%', height:'100%', marginTop: '20px', marginLeft: '40px', marginRight: '40px' }} >
      <ExploreMap
        resetMap={resetMap}
        reports={filteredReports}
        parentSelectedTypes={filters.selectedReportTypes}
        onCheckboxChange={updatedTypes =>
          setFilters(prevFilters => ({ ...prevFilters, selectedReportTypes: updatedTypes }))
        }
      />
      </div>
      <div>

        <Card style={{ width: '93%', marginLeft: '40px', marginRight: '45px' }}>
          <Row>
            <Col style={{ marginTop: '50px', marginLeft: '40px', justifyContent: 'flex-start' }}>
              <CardTitle tag="h5">Reports</CardTitle>
              <Label>Backend Development</Label>
            </Col>
              <Col>
                <div style={{ marginTop: '60px', display: 'flex', justifyContent: 'flex-end' }}>
                  <span style={{ marginRight: '12px', fontSize: '12px' }}>DOWNLOAD</span>
                  {isSuperUser ? (
                    <DownloadButtonBasicReport data={csvData} fileName="Reports" />
                  ) : (
                    <div  onClick={() => handleRestrictedAction("Downloading CSV files is restricted to premium users.")}>
                       <img src={csvIcon} alt="CSV" width="24" height="24" />
                       <span>CSV</span>
                    </div>
                  )}
                </div>
              </Col>
              <Modal className="modal-general" isOpen={modal} toggle={toggleModal}>
                <ModalHeader className="modal-general modal-header"  toggle={toggleModal}>Upgrade Your Membership</ModalHeader>
                <ModalBody  className="modal-general modal-body" >{modalContent}</ModalBody>
              </Modal>
          </Row>
          <ReportsTable reports={filteredReports} />
        </Card>
      </div>

    </div>
  );
};

export default BasicReport;
