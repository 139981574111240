import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, doc, getDoc, where, Timestamp } from 'firebase/firestore';
import TotalCountCardGeneral from './components/TotalCountCardGeneral';
import LineChart from '../variables/LineChart';
import ExploreMapMain from './maps/ExploreMapMain';  
import { Row, Col } from 'reactstrap';
import { firestore, auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { processDataForCharts} from 'utils/chartDataFormatters';
import debounce from 'lodash/debounce';
import LoadingWrapper from 'views/components/LoadingWrapper';
import Refreshable from 'views/components/Refreshable';
import { getCachedData, setCachedData } from 'utils/cacheUtils';
import { useMembership } from "hooks/useMembership";

const CACHE_KEY_MAIN = 'dashboardData';

const fetchData = async (setDashboardData, setIsLoading, isMounted, filterRestrictedReports, isSuperUser) => {
  setIsLoading(true);

  const today = new Date();
  const pastYear = new Date();
  pastYear.setMonth(today.getMonth() - 12);
  const startDate = Timestamp.fromDate(pastYear);
  const endDate = Timestamp.fromDate(today);

  try {
    const [reportsSnapshot, contributorsSnapshot] = await Promise.all([
      getDocs(query(collection(firestore, 'Reports'), where("timeReported", ">=", startDate), where("timeReported", "<=", endDate))),
      getDocs(collection(firestore, 'users')),
    ]);

    if (!isMounted()) return;

    const fetchedReports = [];
    const reportCountsForCards = { ukraineRussia: 0, israelPalestine: 0, violentExtremism: 0, climateChange: 0, federalElections: 0 };
    const totalReportsByMonth = {};
    const ukraineWarTrendByDay = {};
    const israelPalestineWarTrendByMonth = {};
    const totalHazardsSet = new Set();
    const totalCountriesSet = new Set();
    let totalReportsCount = 0;
    let latestTime = null;

    reportsSnapshot.forEach(doc => {
      const report = doc.data();
      const { reportType, country, timeReported, latitude, longitude, hazardType, bombType } = report;
      const reportDate = timeReported.toDate();
      const monthKey = `${reportDate.getFullYear()}-${reportDate.getMonth()}`;
      const dayKey = `${reportDate.getFullYear()}-${reportDate.getMonth()}-${reportDate.getDate()}`;


      if (latitude && longitude && reportType) {
        if (!isSuperUser) {
          const isRestricted = filterRestrictedReports([report]).length === 0;
          if (isRestricted) {
            return; 
          }
        }
        fetchedReports.push({ ...report });
      }
      

      if (country === 'Ukraine' || country === 'Russia') reportCountsForCards.ukraineRussia += 1;
      if (country === 'Israel' || country === 'Palestine') reportCountsForCards.israelPalestine += 1;
      if (reportType === 'terroristActivity') reportCountsForCards.violentExtremism += 1;

      totalReportsCount++;
      if (hazardType) totalHazardsSet.add(hazardType);
      if (bombType) totalHazardsSet.add(bombType);
      totalCountriesSet.add(country);

      totalReportsByMonth[monthKey] = (totalReportsByMonth[monthKey] || 0) + 1;
      if (country === 'Ukraine' || country === 'Russia') {
        ukraineWarTrendByDay[dayKey] = (ukraineWarTrendByDay[dayKey] || 0) + 1;
      }
      if (country === 'Israel' || country === 'Palestine') {
        israelPalestineWarTrendByMonth[monthKey] = (israelPalestineWarTrendByMonth[monthKey] || 0) + 1;
      }

      if (!latestTime || timeReported.seconds > latestTime.seconds) {
        latestTime = timeReported;
      }
    });

    const newData = {
      reports: fetchedReports,
      reportCountsForCards,
      totalReportsTotalCount: totalReportsCount,
      totalContributors: contributorsSnapshot.size,
      totalHazards: totalHazardsSet.size,
      totalCountries: totalCountriesSet.size,
      dataForTotalReports: processDataForCharts(totalReportsByMonth, 'Total Reports', 'rgb(75, 192, 192)'),
      dataForUkraineWarTrend: processDataForCharts(ukraineWarTrendByDay, 'Ukraine/Russia War Reports', 'rgb(255, 99, 132)'),
      dataForIsraelPalestineWarTrend: processDataForCharts(israelPalestineWarTrendByMonth, 'Israel/Palestine War Reports', 'rgb(54, 162, 235)'),
      latestReportTime: latestTime ? new Date(latestTime.seconds * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }) : 'N/A',
    };

    setCachedData(CACHE_KEY_MAIN, newData);
    setDashboardData(newData);
  } catch (error) {
    console.error("Error loading dashboard data:", error);
  } finally {
    setIsLoading(false);
  }
};

const MainDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    reports: [],
    reportCountsForCards: {},
    totalReportsTotalCount: 0,
    totalContributors: 0,
    totalHazards: 0,
    totalCountries: 0,
    dataForTotalReports: { labels: [], datasets: [] },
    dataForUkraineWarTrend: { labels: [], datasets: [] },
    dataForIsraelPalestineWarTrend: { labels: [], datasets: [] },
    latestReportTime: null,
  });
  const [user] = useAuthState(auth);
  const [userMembershipLevel, setUserMembershipLevel] = useState(null);
  const { isSuperUser, filterRestrictedReports } = useMembership(userMembershipLevel);


  useEffect(() => {
    if (user) {
      const fetchMembershipLevel = async () => {
        try {
          const userDocRef = doc(firestore, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            setUserMembershipLevel(userDoc.data()?.membershipLevel || "basic");
          } else {
            setUserMembershipLevel("basic");
          }
        } catch (error) {
          console.error("Error fetching user membership level:", error);
          setUserMembershipLevel("basic");
        }
      };

      fetchMembershipLevel();
    } else {
      setUserMembershipLevel("basic");
    }
  }, [user]);

  useEffect(() => {
    if (userMembershipLevel) {
      let mounted = true;
  
      const loadCachedData = () => {
        const cachedData = getCachedData(CACHE_KEY_MAIN);
        if (cachedData) {
          const filteredReports = isSuperUser ? cachedData.reports : filterRestrictedReports(cachedData.reports);
          setDashboardData({ ...cachedData, reports: filteredReports });
          setIsLoading(false);
          return true;
        }
        return false;
      };
  
      if (!loadCachedData()) {
        fetchData(setDashboardData, setIsLoading, () => mounted, filterRestrictedReports, isSuperUser);
      }
  
      return () => {
        mounted = false;
      };
    }
  }, [userMembershipLevel, isSuperUser, filterRestrictedReports]);
  
 
  const handleRefresh = debounce(async () => {
    setIsRefreshing(true);
    localStorage.removeItem(CACHE_KEY_MAIN); 
    await fetchData(setDashboardData, setIsLoading, () => true, filterRestrictedReports, isSuperUser);
    setIsRefreshing(false);
  }, 500);

  useEffect(() => {
    return () => {
      handleRefresh.cancel();
    };
  }, []);

  
    
  if (isLoading) {
      return <LoadingWrapper isLoading={isLoading} />;
    }

  const { 
    reports, 
    reportCountsForCards, 
    totalReportsTotalCount, 
    totalContributors, 
    totalHazards, 
    totalCountries, 
    dataForTotalReports, 
    dataForUkraineWarTrend, 
    dataForIsraelPalestineWarTrend, 
    latestReportTime 
  } = dashboardData;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '80px'  }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <p style={{ fontSize: '10px', margin: 0, alignSelf: 'center' }}>
          * By default, the dashboard displays data from the past 1 year.
        </p>
        <Refreshable isRefreshing={isRefreshing} onRefresh={handleRefresh} />
     </div>
      {/* Row 1: Total Count Cards */}
      <div className="first-row-cards" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px'   }}>
        <TotalCountCardGeneral title="Ukraine/Russia" count={reportCountsForCards.ukraineRussia} subtitle="View Report" />
        <TotalCountCardGeneral title="Israel/Palestine" count={reportCountsForCards.israelPalestine} subtitle="View Report" />
        <TotalCountCardGeneral title="Violent Extremism" count={reportCountsForCards.violentExtremism} subtitle="View Report" />
        <TotalCountCardGeneral title="Climate Change" count={reportCountsForCards.climateChange} subtitle="View Report" />
        <TotalCountCardGeneral title="Federal Elections" count={0} subtitle="View Report" />
      </div>

      {/* Second Row of Cards */}
      <div className="second-row-cards" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
        <TotalCountCardGeneral title="Total Reports" count={totalReportsTotalCount} subtitle="Last update: " latestUpdate={latestReportTime} wide /> 
        <TotalCountCardGeneral title="Total Contributors" count={totalContributors} subtitle="Current Contributors" wide />
        <TotalCountCardGeneral title="Total Unique Hazards" count={totalHazards} subtitle="Last update: " latestUpdate={latestReportTime} wide />
        <TotalCountCardGeneral title="Total Countries" count={totalCountries} subtitle="Current Countries" wide />
      </div>

      {/* Line Charts */}
      <Row style={{ marginTop: '20px', marginLeft: '2px', width: '100%', marginRight: '25px', }}>
        {dataForTotalReports.datasets.length > 0 &&
          <LineChart title={totalReportsTotalCount} subtitle="Total Reports (Past Year)" data={dataForTotalReports} colorIndex={0} />
        }
        {dataForUkraineWarTrend.datasets.length > 0 &&
          <LineChart title={reportCountsForCards.ukraineRussia} subtitle="Ukraine War Reporting Trend" data={dataForUkraineWarTrend} colorIndex={1} />
        }
        {dataForIsraelPalestineWarTrend.datasets.length > 0 &&
          <LineChart title={reportCountsForCards.israelPalestine} subtitle="Israel/Palestine Reporting Trend" data={dataForIsraelPalestineWarTrend} colorIndex={2} />
        }
      </Row>

      {/* Explore Map Component */}
      <div className="content" style={{ width: '100%', marginLeft: '15px', marginRight: '18px', height: '100%' }}>
        <Row style={{ marginTop: '20px', marginLeft: '2px', width: '100%', marginRight: '25px', }}>
          <Col md="12">
            <ExploreMapMain reports={reports} />  
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MainDashboard;
