import { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const useFileUploader = (storage) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadFiles = async (files, pathPrefix, onProgressCallback) => {
    const totalFiles = files.length;
    let uploadedFiles = 0;

    return Promise.all(
      files.map((file) => {
        const storageRef = ref(storage, `${pathPrefix}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              const overallProgress = Math.round(
                ((uploadedFiles + progress / 100) / totalFiles) * 100
              );
              setUploadProgress(overallProgress);
              if (onProgressCallback) onProgressCallback(overallProgress); 
            },
            reject,
            async () => {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              uploadedFiles++;
              resolve({ url, name: file.name });
            }
          );
        });
      })
    );
  };

  return { uploadFiles, uploadProgress };
};

export default useFileUploader;
