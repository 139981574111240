import React, { useState } from 'react';
import SleuthNetReportsMap from '../maps/SleuthNetReportsMap';
import BoloReportsMap from '../maps/BoloReportsMap';
import NasaReportsMap from '../maps/NasaReportsMap';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import DateRangePickerComponent from '../components/DateRangePickerComponent';;
import { subDays } from 'date-fns';

const MapPage = () => {
  const [activeTab, setActiveTab] = useState('sleuthNet');
  const defaultStartDate = subDays(new Date(), 30);
  const defaultEndDate = new Date();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const handleDateRangeSelect = (startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate });
  };

  
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', marginTop: '60px' }}>
      <Nav style={{ padding: '0 15px', backgroundColor: '#f8f9fa', margin: '10px 20px', display: 'flex', 
            justifyContent: 'space-around', alignItems: 'center' }} >
        {['sleuthNet', 'bolo',
        //  'nasa'
        ].map((tab) => (
          <NavItem key={tab} style={{ flex: 1, textAlign: 'center' }}>
            <div
              className={`custom-nav-link ${activeTab === tab ? 'active' : ''}`}
              onClick={() => toggleTab(tab)}
            >
              {tab === 'sleuthNet' && 'Sleuth Net Reports'}
              {tab === 'bolo' && 'Bolo Reports'}
              {/* {tab === 'nasa' && 'NASA Reports'} */}
            </div>
          </NavItem>
        ))}
      </Nav>
      <div style={{marginTop:'0px' ,position: 'relative',flex: 1, overflow: 'hidden' }}>
      <div
          style={{ position: 'absolute', top: '10px', right: '80px', zIndex: 1000, width: '250px',  
            backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px',
          }}
        >
          <DateRangePickerComponent
            selectedDateRange={selectedDateRange}
            onDateRangeSelect={handleDateRangeSelect}
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
          />
        </div>
      
        <TabContent activeTab={activeTab} style={{ height: '100%' }}>
          <TabPane tabId="sleuthNet" style={{ height: '100%' }}>
            <SleuthNetReportsMap 
            activeTab={activeTab}
            selectedDateRange={selectedDateRange} />
          </TabPane>
          <TabPane tabId="bolo" style={{ height: '100%' }}>
            <BoloReportsMap 
            activeTab={activeTab}
            selectedDateRange={selectedDateRange} />
          </TabPane>
          {/* <TabPane tabId="nasa" style={{ height: '100%' }}>
            <NasaReportsMap activeTab={activeTab} />
          </TabPane> */}
        </TabContent>
      </div>
    </div>
  );
};

export default MapPage;
