import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Container, Row, Col } from 'reactstrap';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const PasswordResetRequestForm = ({ onReturnToLogin }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Check your email inbox for the password reset link.');
      setError('');
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setMessage('');
      setError('Failed to send password reset email. Please make sure the email is correct.');
    }
  };

  return (
    <Container>
      <Row>
        <Col lg="6" md="8" className="mx-auto">
          <Card className="card-login">
            <CardHeader className="text-center">
              <h4>Reset Your Password</h4>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="userEmail">Email Address</Label>
                  <Input
                    type="email"
                    name="email"
                    id="userEmail"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>

                <Button block type="submit" className="btn-round" style={{ backgroundColor:"#2196F3" }}>Send Password Reset Email</Button>
                {message && (
                  <div style={{ color: '#2196F3', textAlign: 'center', marginTop: '10px' }}>
                    {message}
                  </div>
                )}
                {error && (
                  <div style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
                    {error}
                  </div>
                )}
                <div className="text-center mt-3">
                  <Button color="link" onClick={onReturnToLogin}>
                    Return to Login
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordResetRequestForm;
