import MainDashboard from "views/MainDashboard";
import MapPage from "views/pages/MapPage";
import Login from "views/pages/Login.js";
import { ComingSoon } from "views/pages/ComingSoon";
import BasicReportPage from "views/pages/BasicReportPage";
import BasicReportPageBolo from "views/pages/BasicReportPageBolo";
import InvestigationPage from "views/pages/InvestigationPage";
import ContactUs from "views/forms/ContactUs";
import ReportDetails from "views/pages/ViewReport";
import ReportDetailsBolo from "views/pages/ViewReportBolo";
//import ReportDetailsNasa from "views/pages/ViewReportNasa";


const routes = [
  {
    path: "/dashboard",
    name: "Dashboard Home",
    component: MainDashboard,
    layout: "/admin",
  },
  {
    path: "/coming-soon",
    name: "Coming Soon",
    component: ComingSoon,
    layout: "/admin",
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
    layout: "/admin",
  },
  {
    path: "/map",
    name: "Map",
    component: MapPage,
    layout: "/admin",
  },
  {
    path: "/explore-reports",
    name: "Explore All Reports",
    component: BasicReportPage,
    layout: "/admin",
  },
  {
    path: "/explore-reports/bolo",
    name: "Explore Bolo Reports",
    component: BasicReportPageBolo,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/my-investigations",
    name: "My Investigations",
    component: InvestigationPage,
    layout: "/admin",
  },
  {
    path: "/report/view/:reportId",
    name: "View Report",
    component: ReportDetails,
    layout: "/admin",
  },
  {
    path: "/report/bolo/view/:reportId",
    name: "View Report Missing Person",
    component: ReportDetailsBolo,
    layout: "/admin",
  },
  // {
  //   path: "/report/nasa/view/:reportId",
  //   name: "View Report NASA",
  //   component: ReportDetailsNasa,
  //   layout: "/admin",
  // },

  
];

export default routes;
