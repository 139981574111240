import { format } from 'date-fns';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';

export const fetchComments = async (collectionPath, reportId) => {
  try {
    const commentsRef = collection(firestore, `${collectionPath}/${reportId}/Comments`);
    const snapshot = await getDocs(commentsRef);
    const commentsData = snapshot.docs.map((doc) => doc.data());

    const photoUrls = commentsData.flatMap((comment) => comment.supportingPhotoUrl || []);
    const videoUrls = commentsData.flatMap((comment) => comment.supportingVideoUrl || []);
    const documentUrls = commentsData.flatMap((comment) => comment.supportingDocuments || []);

    return { photoUrls, videoUrls, documentUrls };
  } catch (error) {
    console.error(`Error fetching comments for ${collectionPath}/${reportId}:`, error);
    return { photoUrls: [], videoUrls: [], documentUrls: [] };
  }
};

export const CsvDataGenerator = async ({ reports, isBoloReport = false, isDetailedReport = false }) => {
  const formatTimeReported = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== 'function') return 'Invalid date';
    const date = timestamp.toDate();
    return format(date, 'MMMM d, yyyy hh:mm a');
  };

  const collectionPath = isBoloReport ? 'boloReports' : 'Reports';

  const processedReports = await Promise.all(
    reports.map(async (report) => {
      const { photoUrls: commentPhotoUrls, videoUrls: commentVideoUrls, documentUrls: commentDocumentUrls } =
        await fetchComments(collectionPath, report.reportId);

      const combinedPhotoUrls = isDetailedReport ? [...(report.photoUrl || []), ...commentPhotoUrls] : [];
      const combinedVideoUrls = isDetailedReport ? [...(report.videoUrl || []), ...commentVideoUrls] : [];
      const combinedDocumentUrls = isDetailedReport ? [...(report.documentUrl || []), ...commentDocumentUrls] : [];

      const commonFields = {
        reportId: report.reportId || 'N/A',
        reportName: report.reportName || 'N/A',
        reportType: report.reportType || 'N/A',
        originalReportUserId: report.originalReportUserId || 'N/A',
        reportingApp: report.reportingApp || 'N/A',
        city: report.city || 'N/A',
        state: report.state || 'N/A',
        country: report.country || 'N/A',
        latitude: report.latitude || 'N/A',
        longitude: report.longitude || 'N/A',
        contactMethod: report.contactMethod || 'N/A',
        description: report.description || 'N/A',
        originalSocialMediaLink: report.originalSocialMediaLink || 'N/A',
        priority: report.priority || 'N/A',
        timeObserved: report.timeObserved ? formatTimeReported(report.timeObserved) : 'N/A',
        timeReported: report.timeReported ? formatTimeReported(report.timeReported) : 'N/A',
        photos: isDetailedReport ? combinedPhotoUrls.join('; ') : 'N/A',
        videos: isDetailedReport ? combinedVideoUrls.join('; ') : 'N/A',
        documents: isDetailedReport ? combinedDocumentUrls.join('; ') : 'N/A',
      };

      const boloReportFields = {
        firstName: report.firstName || 'N/A',
        lastName: report.lastName || 'N/A',
        age: report.age || 'N/A',
        gender: report.gender || 'N/A',
        raceEthnicity: report.raceEthnicity || 'N/A',
        height: report.height || 'N/A',
        weight: report.weight || 'N/A',
        eyeColor: report.eyeColor || 'N/A',
        hairColor: report.hairColor || 'N/A',
        countriesVisited: report.countriesVisited || 'N/A',
        missingStatus: report.missingStatus || 'N/A',
        modifiedDateTime: report.modifiedDateTime ? formatTimeReported(report.modifiedDateTime) : 'N/A',
      };

      const basicReportFields = {
        hazardType: report.hazardType || 'N/A',
        bombType: report.bombType || 'N/A',
        equipment: report.equipment || 'N/A',
        size: report.size || 'N/A',
        contaminant: report.contaminant || 'N/A',
        protectiveMeasures: report.protectiveMeasures || 'N/A',
        resourcesThreatened: report.resourcesThreatened || 'N/A',
        unitCountry: report.unitCountry || 'N/A',
        withChemicals: report.withChemicals || 'N/A',
        initialSafetyStatus: report.initialSafetyStatus || 'N/A',
        verifiedSafetyStatus: report.verifiedSafetyStatus || 'N/A',
      };

      const specificFields = isBoloReport ? boloReportFields : basicReportFields;

      return {
        ...commonFields,
        ...specificFields,
      };
    })
  );

  return processedReports;
};
