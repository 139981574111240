import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, getDoc, writeBatch, where, doc, addDoc } from 'firebase/firestore';
import { firestore, storage, auth } from '../../firebase';
import { Row, Col, Button, Input, CardBody, CardTitle, CardText, Modal, ModalHeader, ModalBody } from 'reactstrap';
import VideoPlayer from '../components/VideoPlayer';
import PhotoViewer from '../components/PhotoViewer';
import DocumentDownloader from '../components/DocumentDownloader';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { bombTypeMapping, hazardTypeMapping } from '../../utils/reportTypeMapping';
import DownloadButton from '../components/DownloadButton';
import { useAuthState } from "react-firebase-hooks/auth";
import csvIcon from '../../assets/img/csv.png';
import pdfIcon from '../../assets/img/pdf.png';
import { CsvDataGenerator } from '../components/CsvDataGenerator';
import { safetyStatusMapping, safetyStatusMappingSubmit, reportNameMapping } from 'utils/mapping';
import { useMembership } from 'hooks/useMembership';
import { getSafetyStatusStyle } from 'utils/chartDataFormatters';
import LoadingWrapper from 'views/components/LoadingWrapper';
import { shortenURL, isValidURL } from '../../utils/urlHelpers';
import useFileUploader from "hooks/useFileUploader";

function ReportDetails() {
  const { reportId } = useParams();
  const [user] = useAuthState(auth);
  const [userMembershipLevel, setUserMembershipLevel] = useState(null);
  const navigate = useNavigate();
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mostRecentUpdate, setMostRecentUpdate] = useState(null); 
  const [note, setNote] = useState('');
  const [safetyStatus, setSafetyStatus] = useState('Safety conditions are currently unknown');
  const [newSafetyStatus, setNewSafetyStatus] = useState('');
  const mapRef = useRef(null);
  const [supportingUrl, setSupportingUrl] = useState('');
  const [mediaFiles, setMediaFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const referrer = localStorage.getItem('referrer') || '/admin/dashboard';
  const [photoUrls, setPhotoUrls] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [documentUrls, setDocumentUrls] = useState([]);
  const { uploadFiles } = useFileUploader(storage);
  const { isSuperUser, handleRestrictedAction, modal, toggleModal, modalContent, setModalContent } = useMembership(userMembershipLevel || 'basic');
  const [csvData, setCsvData] = useState([]); 
  
  useEffect(() => {
    if (user) {
      const fetchUserMembership = async () => {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserMembershipLevel(userDoc.data().membershipLevel || "basic");
        }
      };
      fetchUserMembership();
    }
  }, [user]);


  const handleDownloadRestriction = () => {
    handleRestrictedAction('Downloading CSV or PDF files is available only for premium users.');
  };

  const handleNoteSubmission = () => {
    handleRestrictedAction('Submitting notes is restricted to premium users.');
  };
  const handleSafetyStatusRestriction = () => {
    handleRestrictedAction('Updating safety status is restricted to premium users.');
  };


  const formatTimeReported = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== 'function') return 'Invalid date';
    const date = timestamp.toDate(); 
    return format(date, 'MMMM d, yyyy hh:mm a', { locale: enUS });
  };

  useEffect(() => {
    async function fetchReportData() {
      setIsLoading(true);
  
      try {

        const reportQuery = query(collection(firestore, 'Reports'), where('reportId', '==', reportId));
        const reportSnapshot = await getDocs(reportQuery);
  
        if (!reportSnapshot.empty) {
          const reportDoc = reportSnapshot.docs[0];
          const reportData = reportDoc.data();
          const reportDocId = reportDoc.id;
  
          const allPhotoUrls = reportData.photoUrl || [];
          const allVideoUrls = reportData.videoUrl || [];
          const allDocumentUrls = reportData.documentUrl || [];
  
          const commentsRef = collection(firestore, 'Reports', reportDocId, 'Comment');
          const commentsSnapshot = await getDocs(commentsRef);
  
          commentsSnapshot.forEach((commentDoc) => {
            const commentData = commentDoc.data();
  
            if (commentData.supportingPhotoUrl) {
              allPhotoUrls.push(...commentData.supportingPhotoUrl);
            }
            if (commentData.supportingVideoUrl) {
              allVideoUrls.push(...commentData.supportingVideoUrl);
            }
            if (commentData.supportingDocuments) {
              allDocumentUrls.push(...commentData.supportingDocuments);
            }
          });
  
          setPhotoUrls([...new Set(allPhotoUrls)]);
          setVideoUrls([...new Set(allVideoUrls)]);
          setDocumentUrls([...new Set(allDocumentUrls)]);
          setReport({ ...reportData, id: reportDocId });
          setSafetyStatus(safetyStatusMapping[reportData.verifiedSafetyStatus || reportData.initialSafetyStatus || 'unknown']);
        } else {
          setSafetyStatus('No report found.');
        }
      } catch (error) {
        console.error('Error fetching report data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  
    fetchReportData();
  }, [reportId]);
  

  const generateCsvData = async () => {
    if (!report) {
      return [];
    }
  
    try {
      return await CsvDataGenerator({
        reports: [report], 
        isBoloReport: false,
        isDetailedReport: true,
      });
    } catch (error) {
      return [];
    }
  };
  
  
 
  useEffect(() => {
    const fetchCsvData = async () => {
      const data = await generateCsvData();
      setCsvData(data);
    };
    fetchCsvData();
  }, [report, mostRecentUpdate, photoUrls, videoUrls, documentUrls]);
  


  useEffect(() => {
    if (report && !isLoading) {
      const google = window.google;
      const latLng = new google.maps.LatLng(report.latitude, report.longitude);

      const mapOptions = {
        zoom: 5,
        center: latLng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };

      const map = new google.maps.Map(mapRef.current, mapOptions);
      
      new google.maps.Marker({
        position: latLng,
        map: map,
        title: report.city,
      });
    }
  }, [report, isLoading]);


  const updateSafetyStatus = async () => {
    if (!isSuperUser) {
      handleSafetyStatusRestriction();
      return;
    }
  
    if (!report || !report.id || newSafetyStatus === '') {
      setModalContent('Report or Report ID is missing. Please select a valid safety status.');
      toggleModal();
      return;
    }
  
    const safetyStatusValue = safetyStatusMappingSubmit[newSafetyStatus];
  
    if (safetyStatusValue === undefined) {
      setModalContent('Please select a valid safety status.');
      toggleModal();
      return;
    }
  
    try {
    
      const updatesRef = collection(firestore, 'Reports', report.id, 'HazardUpdate');
      await addDoc(updatesRef, {
        timeReported: new Date(),
        submittedUserId: user.uid,
        safetyStatus: safetyStatusValue,
      });
  
   
      setNewSafetyStatus('');
      setModalContent('Your safety status update has been successfully submitted!');
      toggleModal();
    } catch (error) {
      console.error('Failed to update the safety status:', error);
      setModalContent('Failed to update the safety status.');
      toggleModal();
    }
  };
  
  
  const submitNote = async () => {
    if (!isSuperUser) {
      handleNoteSubmission();
      return;
    }
  
    setAttemptedSubmit(true);
  
    if (note.trim() === '') {
      setModalContent('Please provide a comment before submitting.');
      toggleModal();
      return;
    }
  
    try {
      if (!report || !report.id) {
        setModalContent('Report ID is missing. Cannot submit comment.');
        toggleModal();
        return;
      }
  
      const totalFiles = mediaFiles.length + documentFiles.length;
      if (totalFiles === 0 && note.trim() === '') {
        setModalContent('Please provide a comment or upload files.');
        toggleModal();
        return;
      }
  
      setModalContent('Uploading files... 0% complete.');
      toggleModal();
  
      const updateModalProgress = (progress) => {
        setModalContent(`Uploading files... ${progress}% complete.`);
      };
  
      const [mediaUrls, documentUrls] = await Promise.all([
        uploadFiles(mediaFiles, `${report.reportType}Report/${report.id}`, updateModalProgress),
        uploadFiles(documentFiles, `${report.reportType}Report/${report.id}`, updateModalProgress),
      ]);
  
      const photoUrls = mediaUrls
        .filter(({ name }) => /\.(jpeg|jpg|png|webp)$/i.test(name))
        .map(({ url }) => url);
  
      const videoUrls = mediaUrls
        .filter(({ name }) => /\.(mp4|mov|avi|mkv)$/i.test(name))
        .map(({ url }) => url);
  
      const documentStrings = documentUrls
        .filter(({ name }) => /\.(pdf|doc|docx|xls|xlsx|ppt|pptx|txt|csv)$/i.test(name))
        .map(({ url }) => url);
  
      const commentData = {
        submittedUserId: user.uid,
        comment: note.trim(),
        timeReported: new Date(),
        archived: false,
        supportingUrl: supportingUrl || null,
        supportingPhotoUrl: photoUrls,
        supportingVideoUrl: videoUrls,
        supportingDocuments: documentStrings,
      };
  
      const batch = writeBatch(firestore);
      const commentRef = doc(collection(firestore, 'Reports', report.id, 'Comment'));
      batch.set(commentRef, commentData);
  
      await batch.commit();
  
      setNote('');
      setSupportingUrl('');
      setMediaFiles([]);
      setDocumentFiles([]);
  
      setModalContent('Your comment and materials have been successfully submitted!');
      toggleModal();
    } catch (error) {
      console.error('Failed to submit comment and materials:', error);
      setModalContent('Failed to submit comment and materials.');
      toggleModal();
    } finally {
      setAttemptedSubmit(false);
    }
  };
  


  const handleFileSelection = (setter, existingFiles) => (event) => {
    const newFiles = Array.from(event.target.files); 
    setter([...existingFiles, ...newFiles]);
  };
  

  const handleFileRemoval = (setter, index) => {
    setter(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

    
  if (isLoading) {
    return <LoadingWrapper isLoading={isLoading} />;
  }

  if (!report) {
    return <div>No report found.</div>;
  }

  const displayData = (data, defaultMessage = 'No information provided') => data || defaultMessage;


  return (
    <div>
            {/* Informational Banner */}
            {!isSuperUser && (
              <div className="membership-banner">
                <strong>Your current membership level: {userMembershipLevel}</strong>. To access full functionality, please{" "}
                <a
                  href="https://sleuthnet.com/account/billing"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#2196F3", textDecoration: "underline" }}
                >
                  upgrade your profile
                </a>.
              </div>
            )}

        <Row  className="align-items-center justify-content-between"
            style={{ marginTop: isSuperUser ? '70px' : '10px' }}>
        <Col xs="auto">
          <button className="btn btn-info" onClick={() => navigate(referrer)} style={{ marginLeft: '20px' }}>
            Go Back
          </button>
        </Col>
        <Col xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '12px', fontSize: '12px' }}>DOWNLOAD</span>
          {isSuperUser ? (
            <DownloadButton
            targetId="report-details-container"
            data={csvData.length > 0 ? csvData : []}
            fileName={`${report?.reportType || 'Report'}_${report?.reportId || 'Unknown'}`}
            isDataLoaded={!isLoading}
          />
          
          
          ) : (
            <div className="download-restriction" onClick={handleDownloadRestriction}>
              <img src={csvIcon} alt="CSV" width="24" height="24" />
              <span>CSV</span>
              <img src={pdfIcon} alt="PDF" width="24" height="24" />
              <span>PDF</span>
            </div>
          )}
        </Col>
      </Row>

      <div id="report-details-container" className="report-details-container">
        <Row>
          <Col lg="6" md="12" style={{ paddingRight: '15px' }}>
            <CardBody>
               <CardText style={{ marginTop: '10px', fontSize: '12px' }}>
                    <span style={{ textTransform: 'uppercase' }}>Last Update: </span> 
                {mostRecentUpdate?.timeReported
                  ? formatTimeReported(mostRecentUpdate.timeReported)
                  :  formatTimeReported(report.timeReported)}
              </CardText>
              <CardTitle style={{ marginTop: '10px' }}>
                <span className='label-text-subtitle'>
                  {reportNameMapping[report.reportType] || displayData(report.reportType)}
                </span>
              </CardTitle>
              <CardText style={{ marginTop: '10px' }}>
                <span className="label-text">Location:</span> {`${displayData(report.city)}, ${displayData(report.country)}`}
              </CardText>
              <CardText>
                <span className='label-text'> Date / Time Observed:</span> {formatTimeReported(report.timeReported)}
              </CardText> 
              <CardText>
                <span className="label-text">Threat Category:</span> {reportNameMapping[report.reportType] || displayData(report.reportType)}
              </CardText>
              {report.reportType === 'uxo' && (
                 <>
                  <CardText style={{marginTop:'20px'}}>
                    <span className='label-text'>Type of Threat:</span> {bombTypeMapping[report.bombType] || 'Unknown'}
                  </CardText>
                  <CardText><span className='label-text'> Protective Measures: </span> {displayData(report.protectiveMeasures)}</CardText>
                  </> )}
                  {['naturalDisaster', 'structureHazard', 'terroristActivity', 'criminalActivity', 'warCrime',
                    'wildlife', 'resourceScarcity', 'publicHealthHazard', 'cyberThreat', 'transportationHazard',
                    'environmentalHazard', 'humanTrafficking'].includes(report.reportType) && (
                   <>
                    <CardText style={{marginTop:'20px'}}>
                      <span className='label-text'>Type of Threat:</span> {hazardTypeMapping[report.hazardType] || 'Unknown'}
                    </CardText>
                     <CardText><span className='label-text'> Protective Measures: </span> {displayData(report.protectiveMeasures)}</CardText>
                     </> )}
              {report.reportType === 'militaryActivity' && (
                  <>
                    <CardText style={{marginTop:'20px'}}>
                      <span className='label-text'>Type of Threat:</span> {hazardTypeMapping[report.hazardType] || 'Unknown'}
                    </CardText>
                    <CardText>
                      <span className="label-text">Equipment:</span> {displayData(report.equipment)}
                    </CardText>
                    <CardText>
                      <span className="label-text">Unit:</span> {displayData(report.unitCountry)}
                    </CardText>
                    <CardText>
                      <span className="label-text">Size:</span> {displayData(report.size)}
                    </CardText>
                  </>
                )}

              <CardText><span className='label-text'> Resources Threatened:</span> {displayData(report.resourcesThreatened)}</CardText>
              <CardText>
                <span className="label-text">Social Media Links: </span>
                {(() => {
                  if (report.originalSocialMediaLink && isValidURL(report.originalSocialMediaLink)) {
                    const shortenedUrl = shortenURL(report.originalSocialMediaLink);
                    return (
                      <a
                        href={report.originalSocialMediaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#007bff', textDecoration: 'underline' }}
                      >
                        {shortenedUrl}
                      </a>
                    );
                  } else {
                    return <span>No valid link available</span>;
                  }
                })()}
              </CardText>

            <CardText><span className='label-text'> Additional Comments:</span> {displayData(report.description)}</CardText>
            </CardBody>
          </Col>

          <Col lg="6" md="12" style={{ marginTop: '60px' }}>
            <div ref={mapRef} className="report-map" style={{ height: '300px' }}></div>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
        <ModalHeader className="modal-general modal-header" toggle={toggleModal}>
          Upgrade Your Membership
        </ModalHeader>
        <ModalBody className="modal-general modal-body">{modalContent}</ModalBody>
      </Modal>

        <Row style={{ marginTop: '30px' }}>
          <Col lg="6" md="12">
            <Row style={{ marginTop: '16px' }}>
              <Col lg="12" md="12">
                <CardTitle className='label-text-subtitle'>Provide Notes</CardTitle>
                <textarea
                  className="notes-input"
                  placeholder="Provide notes and details for the community regarding this specific report...."
                  value={note}
                  onChange={(e) => { setNote(e.target.value); setAttemptedSubmit(false); }}
                  style={{ borderColor: attemptedSubmit && note.trim() === '' ? 'red' : '' }}
                />
                <Input
                  type="text"
                  className="notes-input-url"
                  value={supportingUrl}
                  onChange={(e) => setSupportingUrl(e.target.value)}
                  placeholder="Add URL or Social Media Link"
                />
                <Row style={{ marginTop: '10px', marginLeft: '0px' }}>
                <Button className="btn btn-outline-default">
                <label>
                    Add Photo/Video
                    <Input
                    type="file"
                    id="mediaInput"
                    multiple
                    onChange={handleFileSelection(setMediaFiles, mediaFiles)} 
                    style={{ display: 'none' }}
                    accept="image/*,video/*"
                    />
                </label>
                </Button>
                <Button className="btn btn-outline-default" style={{ marginLeft: '10px' }}>
                    <label>
                        Add Document
                        <Input
                        type="file"
                        id="documentInput"
                        multiple
                        onChange={handleFileSelection(setDocumentFiles, documentFiles)}
                        style={{ display: 'none' }}
                        accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
                        />
                    </label>
                    </Button>

                  {mediaFiles.length > 0 && (
                    <div>
                      {mediaFiles.map((file, index) => (
                        <span key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                          {file.name}
                          <i className="nc-icon nc-simple-remove" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleFileRemoval(setMediaFiles, index)} />
                        </span>
                      ))}
                    </div>
                  )}
                  {documentFiles.length > 0 && (
                    <div>
                      {documentFiles.map((file, index) => (
                        <span key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                          {file.name}
                          <i className="nc-icon nc-simple-remove" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleFileRemoval(setDocumentFiles, index)} />
                        </span>
                      ))}
                    </div>
                  )}
                </Row>

                <div className="button-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start' }}>
                  <button className='btn btn-info' onClick={submitNote}>Add Notes</button>
                </div>
              </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
              <ModalHeader className=".modal-general .modal-header" toggle={toggleModal}>Note Submission</ModalHeader>
              <ModalBody className=".modal-general .modal-body">{modalContent}</ModalBody>
            </Modal>
            <div>
              <CardText className='label-text-subtitle' style={{ marginTop: '50px' }}>Community Notes </CardText>
              <CardText>This section is coming soon ... </CardText>
            </div>
          </Col>

          <Col lg="6" md="12" style={{ marginTop: '15px' }}>
              <div>
                <CardTitle className='label-text-subtitle'>Current Safety Status:</CardTitle>
                <div className="safety-status-box" style={getSafetyStatusStyle(safetyStatus)}>
                  <CardText className="text-uppercase">{safetyStatus}</CardText>
                </div>
              <CardTitle style={{ marginTop: '20px' }} className='label-text-subtitle'>Update Safety Status:</CardTitle>
              <Input
                style={{ marginTop: '20px' }}
                type="select"
                value={newSafetyStatus}
                onChange={e => setNewSafetyStatus(e.target.value)}
              >
                <option value="">Select safety status</option>
                <option value="Area is safe">Area is safe</option>
                <option value="Area is not safe">Area is not safe</option>
                <option value="Safety conditions are currently unknown">Safety conditions are currently unknown</option>
              </Input>

              <button style={{ marginTop: '20px' }} className='btn btn-info' onClick={updateSafetyStatus}>Update Status</button>

              <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
                <ModalHeader className=".modal-general .modal-header" toggle={toggleModal}>Update Confirmation</ModalHeader>
                <ModalBody className=".modal-general .modal-body">{modalContent}</ModalBody>
              </Modal>
            </div>

            <Col style={{ marginTop: '20px' }}>
              <CardTitle><span className='label-text-title'>Photos</span></CardTitle>
              {photoUrls && Array.isArray(photoUrls) && photoUrls.length > 0 ? (
                <PhotoViewer photoUrls={photoUrls} />
              ) : (
                <CardText>No photos attached to the report.</CardText>
              )}
            </Col>

            <Col style={{ marginTop: '20px' }}>
              <CardTitle><span className='label-text-title'>Videos</span></CardTitle>
              {videoUrls && Array.isArray(videoUrls) && videoUrls.length > 0 ? (
                <VideoPlayer videoUrl={videoUrls} />
              ) : (
                <CardText>No videos attached to the report.</CardText>
              )}
            </Col>

            <Col style={{ marginTop: '20px', marginBottom: '20px' }}>
              <CardTitle><span className='label-text-title'>Documents</span></CardTitle>
              {documentUrls && Array.isArray(documentUrls) && documentUrls.length > 0 ? (
                <DocumentDownloader documentUrls={documentUrls} />
              ) : (
                <CardText>No documents attached to the report.</CardText>
              )}
            </Col>
            

          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ReportDetails;