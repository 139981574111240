import { useState, useMemo } from "react";

export const useMembership = (userMembershipLevel) => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const toggleModal = () => setModal(!modal);

  const isSuperUser = userMembershipLevel === "superUser" || userMembershipLevel === "expert";

  const handleRestrictedAction = (customMessage) => {
    if (!isSuperUser) {
      setModalContent(
        <div className="modal-container">
          <h5 className="modal-message">{customMessage || "This action is restricted to premium users."}</h5>
          <p className="modal-cta">
            Unlock full functionality by upgrading your membership to a premium plan.
          </p>
          <a
            href="https://sleuthnet.com/account/billing"
            target="_blank"
            rel="noopener noreferrer"
            className="modal-upgrade-link"
          >
            Upgrade Membership
          </a>
        </div>
      );
      toggleModal();
    }

    return isSuperUser;
  };

  
  const filterRestrictedReports = useMemo(() => {
    return (reports, restrictedReportTypes = ["militaryActivity"]) => {
      if (isSuperUser) {
        return reports;
      }

      const now = new Date();

      return reports.filter((report) => {
        if (restrictedReportTypes.includes(report.reportType)) {
          const reportTime = new Date(report.timeReported?.seconds * 1000 || report.timeReported);
          const timeDifference = now - reportTime;
          return timeDifference >= 48 * 60 * 60 * 1000; 
        }
        return true;
      });
    };
  }, [isSuperUser]);

  return {
    isSuperUser,
    handleRestrictedAction,
    filterRestrictedReports,
    modal,
    toggleModal,
    modalContent,
    setModalContent,
  };
};


