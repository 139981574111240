import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import VideoPlayer from './VideoPlayer';
import PhotoViewer from './PhotoViewer';
import { reportNameMapping } from 'utils/mapping';

const ReportModal = ({ isOpen, toggle, report, safetyStatus, timeObserved, photoUrls, videoUrls, activeTab }) => {
  const [showMedia, setShowMedia] = useState(false);

  const handleMediaClick = () => {
    setShowMedia(true);
  };
  const viewReportUrl = 
  activeTab === 'bolo' 
    ? `report/bolo/view/${report?.id}` 
     : activeTab === 'nasa' 
     ? `report/nasa/view/${report?.id}` 
    : activeTab === 'sleuthNet' 
    ? `report/view/${report?.id}`
    : `report/view/${report?.id}`; 


  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: '320px' }}>
      <ModalBody style={{ padding: '16px', color: 'black', fontFamily: 'Montserrat', fontSize: '14px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 style={{ paddingTop: '15px', margin: '0', fontWeight: 'bold', textTransform: 'uppercase', fontSize: '16px' }}>
            {report ? reportNameMapping[report.reportType] : 'Report Details'}
          </h4>
          <Button close onClick={toggle} style={{ padding: 0, margin: 0 }} />
        </div>
        <p style={{ paddingTop: '15px', fontFamily: 'Montserrat', fontSize: '15px' }}>{safetyStatus}</p>
        <p style={{ marginBottom: '15px', fontFamily: 'Montserrat', fontSize: '11px' }}>{timeObserved}</p>

        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            className="btn btn-info"
            href={viewReportUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ flex: 1, fontSize: '14px', padding: '8px' }}
          >
            View More
          </Button>
          {(photoUrls.length > 0 || videoUrls.length > 0) && (
             <Button
             className="btn btn-outline-neutral"
             onClick={handleMediaClick}
             style={{ flex: 1, fontSize: '14px', padding: '8px' }}
           >
             View Media
           </Button>
          )}
        </div>

        {showMedia && (
          <Modal isOpen={showMedia} toggle={() => setShowMedia(false)} centered style={{ maxWidth: '600px' }}>
            <ModalBody>
              {photoUrls.length > 0 ? (
                <PhotoViewer photoUrls={photoUrls} />
              ) : (
                <p style={{ textAlign: 'center', color: '#555' }}>No photos available</p>
              )}
              {videoUrls.length > 0 ? (
                <VideoPlayer videoUrl={videoUrls} />
              ) : (
                <p style={{ textAlign: 'center', color: '#555' }}>No videos available</p>
              )}
            </ModalBody>
          </Modal>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ReportModal;
