import LZString from 'lz-string';

const DEFAULT_CACHE_EXPIRATION_MS = 1000 * 60 * 60; // 1 hour

export const getCachedData = (key, expiration = DEFAULT_CACHE_EXPIRATION_MS) => {
  try {
    const compressed = localStorage.getItem(key);
    if (!compressed) return null;

    const decompressed = LZString.decompressFromUTF16(compressed);
    const cache = JSON.parse(decompressed);

    if (cache && Date.now() - cache.timestamp < expiration) {
      return cache.data;
    }
  } catch (error) {
    console.warn(`Error reading cache for key: ${key}`, error);
  }
  return null;
};


export const setCachedData = (key, data) => {
  try {
    const jsonString = JSON.stringify({ data, timestamp: Date.now() });
    const compressed = LZString.compressToUTF16(jsonString);

    const sizeInBytes = new Blob([compressed]).size;

    if (sizeInBytes > 5 * 1024 * 1024) {
      console.warn(`Cache size for key "${key}" exceeds localStorage limit and will not be saved.`);
      return;
    }

    localStorage.setItem(key, compressed);
  } catch (error) {
    console.warn(`Error setting cache for key: ${key}`, error);
  }
};
