import React, { useState, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import { subDays, startOfDay, endOfDay, isValid, format } from 'date-fns';
import { FormGroup, Input, Button } from 'reactstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateRangePickerComponent = ({ selectedDateRange, onDateRangeSelect, defaultStartDate, defaultEndDate }) => {
    const [dateRange, setDateRange] = useState([
        { startDate: selectedDateRange.startDate, endDate: selectedDateRange.endDate, key: 'selection' }
    ]);
    const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
    const inputRef = useRef(null); 
    const handleSelectDateRange = (ranges) => {
        const { selection } = ranges;
        const adjustedStartDate = startOfDay(selection.startDate);
        const adjustedEndDate = selection.endDate
            ? endOfDay(selection.endDate)
            : endOfDay(selection.startDate);

        setDateRange([{ ...selection, startDate: adjustedStartDate, endDate: adjustedEndDate }]);
        onDateRangeSelect(adjustedStartDate, adjustedEndDate);
    };

    const handleResetDateRange = () => {
        const resetRange = { startDate: defaultStartDate, endDate: defaultEndDate, key: 'selection' };
        setDateRange([resetRange]);
        onDateRangeSelect(defaultStartDate, defaultEndDate);
        setOpenDateRangePicker(false);
    };

    const formattedStartDate = isValid(dateRange[0].startDate) ? format(dateRange[0].startDate, 'MM/dd/yyyy') : '';
    const formattedEndDate = isValid(dateRange[0].endDate) ? format(dateRange[0].endDate, 'MM/dd/yyyy') : '';

    const staticRanges = [
        {
            label: 'Last 24 Hours',
            range: () => ({
                startDate: subDays(new Date(), 2),
                endDate: new Date(),
            }),
            isSelected(range) {
                const { startDate, endDate } = this.range();
                return startDate.getTime() === range.startDate.getTime() && endDate.getTime() === range.endDate.getTime();
            }
        },
        {
            label: 'Last 7 Days',
            range: () => ({
                startDate: subDays(new Date(), 7),
                endDate: new Date(),
            }),
            isSelected(range) {
                const { startDate, endDate } = this.range();
                return startDate.getTime() === range.startDate.getTime() && endDate.getTime() === range.endDate.getTime();
            }
        },
        {
            label: 'Last 30 Days',
            range: () => ({
                startDate: subDays(new Date(), 30),
                endDate: new Date(),
            }),
            isSelected(range) {
                const { startDate, endDate } = this.range();
                return startDate.getTime() === range.startDate.getTime() && endDate.getTime() === range.endDate.getTime();
            }
        },
    ];

    // Toggle visibility of date picker
    const toggleOpenDateRangePicker = () => {
        setOpenDateRangePicker(!openDateRangePicker);
    };

    return (
        <FormGroup>
            <div style={{ width: '100%', position: 'relative' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                        readOnly
                        value={`${formattedStartDate} - ${formattedEndDate}`}
                        onClick={toggleOpenDateRangePicker}
                        placeholder="Select Date Range"
                        style={{ fontSize: '12px', color: 'grey' }}
                        className="date-picker-input"
                        innerRef={inputRef}
                    />
                    <Button
                        className="btn-reset"
                        onClick={handleResetDateRange}
                        style={{ marginLeft: '10px', fontSize: '12px', padding: '5px 10px' }}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </Button>
                </div>
                {openDateRangePicker && (
                    <div
                        className="date-range-picker-container"
                        style={{
                            position: 'absolute',
                            zIndex: 1000,
                            top: '40px',
                            left: 0,
                            width: '280px',
                            backgroundColor: '#fff',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            padding: '1px',
                            borderRadius: '8px'
                        }}
                    >
                        <DateRangePicker
                            onChange={handleSelectDateRange}
                            ranges={dateRange}
                            staticRanges={staticRanges}
                            inputRanges={[]}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            direction="vertical"
                        />
                    </div>
                )}
            </div>
        </FormGroup>
    );
};

export default DateRangePickerComponent;
