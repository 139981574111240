//for Bolo report dashboard 
export const formatAgeDataForChart = (ageGroups) => {
    const labels = Object.keys(ageGroups);
    const data = Object.values(ageGroups);
  
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
            "#9966FF", "#FF9F40", "#B23CFD", "#FFA500"
          ],
          hoverBackgroundColor: [
            "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
            "#9966FF", "#FF9F40", "#B23CFD", "#FFA500"
          ],
        },
      ],
    };
  };
  
  export const formatGenderDataForChart = (genderGroups) => {
    const labels = Object.keys(genderGroups);
    const data = Object.values(genderGroups);
  
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };
  };
  
  export const formatMissingStatusDataForChart = (missingStatusGroups) => {
    const labels = Object.keys(missingStatusGroups);
    const data = Object.values(missingStatusGroups);
  
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#9966FF"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#9966FF"],
        },
      ],
    };
  };
  
//for Bolo View report page 

  export const getMissingStatusStyle = (status) => {
    switch (status) {
      case 'Person is found':
        return { backgroundColor: '#3DBF00', color: 'white' };
      case 'Person is deseased':
        return { backgroundColor: '#792F2A', color: 'white' };
      case 'Person is missing':
        return { backgroundColor: '#FFD700', color: 'black' };
      case 'Cold case':
        return { backgroundColor: '#D3D3D3', color: 'black' };
      default:
        return { backgroundColor: '#D3D3D3', color: 'black' };
    }
  };
//for General View report page 
  export const getSafetyStatusStyle = (status) => {
    switch (status) {
      case 'Area is safe':
        return { backgroundColor: '#3DBF00', color: 'white' };
      case 'Area is not safe':
        return { backgroundColor: '#792F2A', color: 'white' };
      case 'Safety conditions are currently unknown':
        return { backgroundColor: '#FFD700', color: 'black' };
      default:
        return { backgroundColor: '#D3D3D3', color: 'black' };
    }
  };


  //for Main Dashbaord 
  export const processDataForCharts = (data, label, color) => {
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const labels = Object.keys(data).map(key => {
      const [year, month] = key.split('-');
      return `${monthNames[parseInt(month)]} ${year}`;
    });
    const values = Object.values(data);
    return {
      labels,
      datasets: [{
        label,
        data: values,
        borderColor: color,
        fill: false,
      }],
    };
  };