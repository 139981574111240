import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, getDocs, query, getDoc,doc} from 'firebase/firestore';
import { Row, Col, Card, FormGroup, Label, CardBody, CardTitle, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { firestore, auth } from '../../firebase';
import ExploreMapBolo from '../maps/ExploreMapBolo';
import ReportsTableBolo from '../tables/ReportsTableBolo';
import DownloadButtonBasicReport from '../components/DownloadButtonBasicReport'; 
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import CustomizableSelect from 'views/components/CustomizableSelect';
import DateRangePickerComponent from 'views/components/DateRangePickerComponent';
import LoadingWrapper from 'views/components/LoadingWrapper';
import Refreshable from 'views/components/Refreshable';
import PieChart from 'variables/PieChart';
import csvIcon from '../../assets/img/csv.png';
import { useAuthState } from "react-firebase-hooks/auth";
import { CsvDataGenerator } from '../components/CsvDataGenerator';
import { groupAges } from 'utils/mapping';
import { geographicRegions } from 'utils/region';
import { useMembership } from 'hooks/useMembership';
import { formatAgeDataForChart, formatGenderDataForChart, formatMissingStatusDataForChart} from 'utils/chartDataFormatters';
import debounce from 'lodash/debounce';
import { getCachedData, setCachedData } from 'utils/cacheUtils';

const CACHE_KEY_BOLO = 'cachedReportsBolo'; 

const BasicReportBolo = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedApp, setSelectedApp] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedInvestigation, setSelectedInvestigation] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(subMonths(new Date(), 1)),
    endDate: endOfDay(new Date()),
  });

  const [selectedReportTypes, setSelectedReportTypes] = useState([]); 
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [apps, setApps] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [resetMap, setResetMap] = useState(false); 
  const [ageData, setAgeData] = useState({});
  const [genderData, setGenderData] = useState({});
  const [missingStatusData, setMissingStatusData] = useState({});
  const [user] = useAuthState(auth);
  const [userMembershipLevel, setUserMembershipLevel] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { isSuperUser, handleRestrictedAction, modal, toggleModal, modalContent} = useMembership(userMembershipLevel);



  useEffect(() => {
    if (user) {
      const fetchUserMembership = async () => {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserMembershipLevel(userDoc.data().membershipLevel || "basic");
        }
      };
      fetchUserMembership();
    }
  }, [user]);
  
  
  
  const groupByField = (reports, field, map = null) => {
    const groups = {};
  
    reports.forEach((report) => {
      let key = report[field] || "Unknown";
      if (map && map[key]) {
        key = map[key].text; 
      }
      groups[key] = (groups[key] || 0) + 1;
    });
  
    return groups;
  };
  
  
  const missingStatusMap = {
    found: { text: 'Person is Found' },
    deceased: { text: 'Person is Deceased' },
    coldCase: { text: 'Cold Case' },
    missing: { text: 'Person is Missing' }
  };
 
  
  const fetchData = useCallback(async (forceRefresh = false) => {
    setIsLoading(true);
    try {
    
      if (!forceRefresh) {
        const cachedReports = getCachedData(CACHE_KEY_BOLO);
        if (cachedReports) {
          setReports(cachedReports);
          setFilteredReports(cachedReports);
          return;
        }
      }
      const q = query(collection(firestore, 'boloReport'));
      const querySnapshot = await getDocs(q);
  
      const reportsList = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          documentId: doc.id,
          reportId: data.reportId,
          reportName: data.reportName,
          reportType: data.reportType,
          originalReportUserId: data.originalReportUserId,
          reportingApp: data.reportingApp,
          city: data.city,
          state: data.state,
          country: data.country,
          latitude: data.latitude,
          longitude: data.longitude,
          contactMethod: data.contactMethod,
          timeReported: data.timeReported,
          description: data.description,
          originalSocialMediaLink: data.originalSocialMediaLink,
          missingStatus: data.missingStatus,
          firstName: data.firstName,
          lastName: data.lastName,
          age: data.age ?? 'N/A',
          gender: data.gender ?? 'N/A',
          raceEthnicity: data.raceEthnicity || 'N/A',
          height: data.height || 'N/A',
          weight: data.weight || 'N/A',
          eyeColor: data.eyeColor || 'N/A',
          hairColor: data.hairColor || 'N/A',
          countriesVisited: data.countriesVisited || 'N/A',
           // verificationStatus: data.verificationStatus,
          // verificationTimestamp: data.verificationTimestamp,
          // verificationUpVotes: data.verificationUpVotes,
          // verificationDownVotes: data.verificationUpVotes,
        };
      });

      setReports(reportsList);
      setFilteredReports(reportsList);
      setCachedData(CACHE_KEY_BOLO, reportsList);
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  

  useEffect(() => {
    setResetMap(false);
    if (location.state) {
      const { country, app, region, state, city, status, investigation, dateRange } = location.state;
  
      setSelectedRegion(region || []);
      setSelectedCountry(country || []);
      setSelectedState(state || []);
      setSelectedCity(city || []);
      setSelectedApp(app || []);
      setSelectedStatus(status || []);
      setSelectedInvestigation(investigation || []);
  
      setSelectedDateRange({
        startDate: dateRange?.startDate || startOfDay(subMonths(new Date(), 1)),
        endDate: dateRange?.endDate || endOfDay(new Date()),
      });
      setResetMap(true);
    } else {
      setSelectedRegion([]);
      setSelectedCountry([]);
      setSelectedState([]);
      setSelectedCity([]);
      setSelectedApp([]);
      setSelectedStatus([]);
      setSelectedInvestigation([]);
      setSelectedDateRange({
        startDate: startOfDay(subMonths(new Date(), 1)),
        endDate: endOfDay(new Date()),
      });
      setResetMap(true);
    }
  
    fetchData(); 
  }, [location.state, fetchData]);
  

const updateAvailableOptions = (filtered) => {
    const availableCountries = Array.from(new Set(filtered.map((report) => report.country)));
    setCountries(availableCountries.map((country) => ({ value: country, label: country })));

    const availableStates = Array.from(new Set(filtered.map((report) => report.state)));
    setStates(availableStates.map((state) => ({ value: state, label: state })));

    const availableCities = Array.from(new Set(filtered.map((report) => report.city)));
    setCities(availableCities.map((city) => ({ value: city, label: city })));

    const availableApps = Array.from(new Set(filtered.map((report) => report.reportingApp)));
    setApps(availableApps.map((app) => ({ value: app, label: app })));
    
    const availableStatuses = Array.from(new Set(filtered.map((report) => report.missingStatus))).map((status) => ({
    value: status, label: missingStatusMap[status]?.text || status,}));
    setStatuses(availableStatuses);

    const availableInvestigations = Array.from(new Set(filtered.map((report) => report.investigation)));
    setInvestigations(availableInvestigations.map((investigation) => ({ value: investigation, label: investigation })));
};

const applyFilters = () => {
    let filtered = [...reports];

    if (selectedRegion.length > 0) {
        filtered = filtered.filter((report) =>
            selectedRegion.some((region) =>
                geographicRegions[region]?.includes(report.country)
            )
        );
    }

    if (selectedCountry.length > 0) {
        filtered = filtered.filter((report) =>
            selectedCountry.includes(report.country)
        );
    }

    if (selectedState.length > 0) {
        filtered = filtered.filter((report) =>
            selectedState.includes(report.state)
        );
    }

    if (selectedCity.length > 0) {
        filtered = filtered.filter((report) =>
            selectedCity.includes(report.city)
        );
    }

    if (selectedApp.length > 0) {
        filtered = filtered.filter((report) =>
            selectedApp.includes(report.reportingApp)
        );
    }

    if (selectedStatus.length > 0) {
        filtered = filtered.filter((report) =>
            selectedStatus.includes(report.missingStatus)
        );
    }

    if (selectedInvestigation.length > 0) {
        filtered = filtered.filter((report) =>
            selectedInvestigation.includes(report.investigation)
        );
    }

    filtered = filtered.filter((report) => {
        if (report.timeReported && report.timeReported.seconds) {
            const reportDate = new Date(report.timeReported.seconds * 1000);
            return (
                (!selectedDateRange.startDate || reportDate >= selectedDateRange.startDate) &&
                (!selectedDateRange.endDate || reportDate <= selectedDateRange.endDate)
            );
        }
        return true;
    });

    updateAvailableOptions(filtered);
    setFilteredReports(filtered);

    const ageGroups = groupAges(filtered);
    const genderGroups = groupByField(filtered, "gender");
    const missingStatusGroups = groupByField(filtered, "missingStatus",missingStatusMap); 

    setAgeData(formatAgeDataForChart(ageGroups));
    setGenderData(formatGenderDataForChart(genderGroups));
    setMissingStatusData(formatMissingStatusDataForChart(missingStatusGroups));
};


  useEffect(() => {
    applyFilters();
  }, [reports, selectedRegion, selectedCountry, selectedState, selectedCity, selectedApp, selectedStatus, selectedInvestigation, selectedDateRange, selectedReportTypes]);

  const handleFilterChange = (filterType, selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    switch (filterType) {
      case 'region':
        setSelectedRegion(selectedValues);
        break;
      case 'country':
        setSelectedCountry(selectedValues);
        break;
      case 'state':
        setSelectedState(selectedValues);
        break;
      case 'city':
        setSelectedCity(selectedValues);
        break;
      case 'app':
        setSelectedApp(selectedValues);
        break;
      case 'status':
        setSelectedStatus(selectedValues);
        break;
      case 'investigation':
        setSelectedInvestigation(selectedValues);
        break;
      default:
        break;
    }
  };

  const handleDateRangeSelect = (startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const handleCheckboxChange = (updatedSelectedTypes) => {
    setSelectedReportTypes(updatedSelectedTypes);
  };

  const generateCsvData = async () => {
  try {
    const data = await CsvDataGenerator({
      reports: filteredReports,
      isBoloReport: true, 
      isDetailedReport: false, 
    });
    setCsvData(data);
  } catch (error) {
  }
};
  useEffect(() => {
    generateCsvData();
  }, [filteredReports]);

  const handleRefresh = debounce(async () => {
    localStorage.removeItem(CACHE_KEY_BOLO); 
    setIsRefreshing(true);
    await fetchData(true);
    setIsRefreshing(false);
  }, 500);
  
  useEffect(() => {
    return () => {
      handleRefresh.cancel(); 
    };
  }, []);


  if (isLoading) {
    return <LoadingWrapper isLoading={isLoading} />;
  }
  

return (
  <div>
    
     <Card style={{ width: '93%', marginTop: '75px', marginLeft: '40px', marginRight: '40px' }}>
          <CardBody>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <p style={{ fontSize: '10px', margin: 0 }}>
              * By default, the dashboard displays data from the past 30 days.
            </p>
            <Refreshable isRefreshing={isRefreshing} onRefresh={handleRefresh} />
          </div>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label>Geographic Region</Label>
                <CustomizableSelect
                  options={Object.keys(geographicRegions).map((region) => ({
                    value: region,
                    label: region,
                  }))}
                  isMulti
                  value={selectedRegion.map((region) => ({ value: region, label: region }))}
                  onChange={(selectedOptions) => handleFilterChange('region', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Country</Label>
                <CustomizableSelect
                  options={countries}
                  isMulti
                  value={selectedCountry.map((country) => ({ value: country, label: country }))}
                  onChange={(selectedOptions) => handleFilterChange('country', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>State/Province</Label>
                <CustomizableSelect
                  options={states}
                  isMulti
                  value={selectedState.map((state) => ({ value: state, label: state }))}
                  onChange={(selectedOptions) => handleFilterChange('state', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>City</Label>
                <CustomizableSelect
                  options={cities}
                  isMulti
                  value={selectedCity.map((city) => ({ value: city, label: city }))}
                  onChange={(selectedOptions) => handleFilterChange('city', selectedOptions)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label>Investigation</Label>
                <CustomizableSelect
                  options={investigations}
                  isMulti
                  value={selectedInvestigation.map((investigation) => ({ value: investigation, label: investigation }))}
                  onChange={(selectedOptions) => handleFilterChange('investigation', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Reporting App</Label>
                <CustomizableSelect
                  options={apps}
                  isMulti
                  value={selectedApp.map((app) => ({ value: app, label: app }))}
                  onChange={(selectedOptions) => handleFilterChange('app', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label>Missing Status</Label>
                <CustomizableSelect
                options={statuses}
                isMulti
                value={selectedStatus.map((status) => ({
                    value: status,
                    label: missingStatusMap[status]?.text || status,
                }))}
                onChange={(selectedOptions) => handleFilterChange('status', selectedOptions)}
                />
            </FormGroup>
            </Col>


            <Col md={3}>
              <FormGroup>
                <Label>Date Range</Label>
                <DateRangePickerComponent
                  selectedDateRange={selectedDateRange}
                  onDateRangeSelect={handleDateRangeSelect}
                  defaultStartDate={startOfDay(subMonths(new Date(), 1))}
                  defaultEndDate={endOfDay(new Date())}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div style={{  width: '93%', height:'100%', marginTop: '20px', marginLeft: '40px', marginRight: '40px' }} >
      <ExploreMapBolo
        resetMap={resetMap}
        reports={filteredReports}
        parentSelectedTypes={selectedReportTypes}
        onCheckboxChange={handleCheckboxChange}
        //selectedCountries={selectedCountry}
      />

      </div>
      <div  style={{ width: '93%', marginLeft: '40px', marginRight: '45px' }} >
        {/* Pie Charts */}

        <Row>
            <Col md={4}>
                <PieChart
                data={ageData}
                title="Reports by Age"
                subtitle="Distribution of reports based on age groups"
                />
            </Col>
            <Col md={4}>
                <PieChart
                data={genderData}
                title="Reports by Gender"
                subtitle="Distribution of reports by gender"
                />
            </Col>
            <Col md={4}>
                <PieChart
                data={missingStatusData}
                title="Reports by Missing Status"
                subtitle="Distribution of missing status categories"
                />
            </Col>
        </Row>
        {/* Pie Charts */}
    </div>
      <div>
        <Card style={{ width: '93%', marginLeft: '40px', marginRight: '45px' }}>
          <Row>
            <Col style={{ marginTop: '50px', marginLeft: '40px', justifyContent: 'flex-start' }}>
              <CardTitle tag="h5">Reports</CardTitle>
              <Label>Backend Development</Label>
            </Col>
            <Col>
               <div style={{ marginTop: '60px', display: 'flex', justifyContent: 'flex-end' }}>
                  <span style={{ marginRight: '12px', fontSize: '12px' }}>DOWNLOAD</span>
                  {isSuperUser ? (
                    <DownloadButtonBasicReport data={csvData} fileName="Reports" />
                  ) : (
                    <div  onClick={() => handleRestrictedAction("Downloading CSV files is restricted to premium users.")}>
                       <img src={csvIcon} alt="CSV" width="24" height="24" />
                       <span>CSV</span>
                    </div>
                  )}
               </div>
              </Col>
              <Modal className="modal-general" isOpen={modal} toggle={toggleModal}>
                <ModalHeader className="modal-general modal-header"  toggle={toggleModal}>Upgrade Your Membership</ModalHeader>
                <ModalBody  className="modal-general modal-body" >{modalContent}</ModalBody>
              </Modal>
          </Row>
          <ReportsTableBolo reports={filteredReports} />
        </Card>
      </div>

    </div>
  );
};

export default BasicReportBolo;
