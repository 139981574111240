export const shortenURL = (url) => (url.length > 30 ? url.slice(0, 30) + '...' : url);

export const isValidURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};
