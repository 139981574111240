import React from "react";
import { Spinner } from "reactstrap";


const LoadingWrapper = ({ isLoading, children }) => {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spinner />
          <p> Please wait, we are fetching the data for you...</p>
        </div>
      );
    }
    return <>{children}</>;
  };
  export default LoadingWrapper;
  