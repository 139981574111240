import React, { useEffect, useState, useCallback, useRef } from 'react';
import { collection, query, getDocs,  where, Timestamp, doc, getDoc } from 'firebase/firestore';
import { useAuthState } from "react-firebase-hooks/auth";
import { firestore, auth } from '../../firebase';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import ReportModal from '../components/ReportModal';
import LoadingWrapper from '../components/LoadingWrapper';
import { safetyStatusMapping } from 'utils/mapping';
import { getCachedData, setCachedData } from 'utils/cacheUtils';
import { useMembership } from "hooks/useMembership"; 

const getIconUrl = (reportType) => {
  let iconUrl;
  try {
    iconUrl = require(`assets/img/typeofendpoints/${reportType}.png`);
  } catch (error) {
    iconUrl = "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless_hdpi.png";
  }
  return iconUrl;
};

const formatTimeReported = (timeObserved) => {
  if (!timeObserved?.seconds) return 'Unknown Time';
  const date = new Date(timeObserved.seconds * 1000);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) +
    ' ' + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
};

const SleuthNetReportsMap = ({ activeTab, selectedDateRange }) => {
  
  const [user] = useAuthState(auth);
  const [userMembershipLevel, setUserMembershipLevel] = useState(null);

  const { filterRestrictedReports } = useMembership(userMembershipLevel); 
  const mapRef = useRef(null);
  const [mapData, setMapData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [latestSafetyStatus, setLatestSafetyStatus] = useState(null);
  const [latestTimeReported, setLatestTimeReported] = useState(null);
  const [latestPhotoUrl, setLatestPhotoUrl] = useState([]);
  const [latestVideoUrl, setLatestVideoUrl] = useState([]);

  const CACHE_KEY = `sleuthNetData_${JSON.stringify(selectedDateRange)}`;
  
  useEffect(() => {
    const fetchUserMembership = async () => {
      if (!user) return;

      try {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setUserMembershipLevel(userDoc.data().membershipLevel || "basic");
        }
      } catch (error) {
        console.error("Error fetching user membership level:", error);
      }
    };

    fetchUserMembership();
  }, [user]);
  
  const fetchReportData = useCallback(async () => {
    setIsLoading(true);
    const cachedData = getCachedData(CACHE_KEY);
    if (cachedData) {
      setMapData(filterRestrictedReports(cachedData));
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      const q = query(
        collection(firestore, 'Reports'),
        where('timeObserved', '>=', Timestamp.fromDate(selectedDateRange.startDate)),
        where('timeObserved', '<=', Timestamp.fromDate(selectedDateRange.endDate))
      );
      const querySnapshot = await getDocs(q);
      const reports = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        latLng: { lat: parseFloat(doc.data().latitude), lng: parseFloat(doc.data().longitude) },
      }));
      const restrictedReports = filterRestrictedReports(reports);
      setCachedData(CACHE_KEY, restrictedReports);
      setMapData(restrictedReports);
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }, [selectedDateRange, filterRestrictedReports, CACHE_KEY]);
  

  const fetchCompleteReportData = async (report) => {
    try {
      let photoUrl = (report.photoUrl || []).filter(Boolean);
      let videoUrl = (report.videoUrl || []).filter(Boolean);

      const safetyStatus = report.verifiedSafetyStatus || report.initialSafetyStatus || 'unknown';
      const timeObserved = report.timeObserved;
  
      const updateSnapshot = await getDocs(collection(firestore, `Reports/${report.id}/Comment`));
      updateSnapshot.forEach((subDoc) => {
        const updateData = subDoc.data();
        photoUrl = [...photoUrl, ...(updateData.supportingPhotoUrl || []).filter(Boolean)];
        videoUrl = [...videoUrl, ...(updateData.supportingVideoUrl || []).filter(Boolean)];
      });

      setLatestSafetyStatus(safetyStatusMapping[safetyStatus] || safetyStatusMapping['unknown']);
      setLatestPhotoUrl(photoUrl);
      setLatestVideoUrl(videoUrl);
      setLatestTimeReported(timeObserved);
      setSelectedMarker(report);
    } catch (error) {
      console.error('Error fetching complete report data:', error);
    }
  };
  
  const openModal = (report) => fetchCompleteReportData(report);

  const initializeMap = () => {
    if (!mapRef.current) return;

    const google = window.google;
    if (mapRef.current.markers) {
      mapRef.current.markers.forEach(marker => marker.setMap(null));
      mapRef.current.markers = [];
    }

    const map = new google.maps.Map(mapRef.current, {
      zoom: 2,
      center: { lat: 20, lng: 0 },
    });

    if (mapData.length === 0) {
      mapRef.current.map = map;
      return;
    }

    const markers = mapData.map(report => {
      const marker = new google.maps.Marker({
        position: report.latLng,
        title: report.city || 'Unknown Location',
        icon: {
          url: getIconUrl(report.reportType),
          scaledSize: new google.maps.Size(30, 30),
        },
      });

      marker.addListener('click', () => openModal(report));
      return marker;
    });

    mapRef.current.markers = markers;
    new MarkerClusterer({ markers, map });
  };
  
  useEffect(() => {
    if (userMembershipLevel) {
      fetchReportData();
    }
  }, [fetchReportData, userMembershipLevel]);


useEffect(() => {
  if (!isLoading) {
    initializeMap();
  }
}, [isLoading, mapData]);


  return (
    <LoadingWrapper isLoading={isLoading}>
    <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
      {selectedMarker && (
        <ReportModal
          isOpen={!!selectedMarker}
          toggle={() => setSelectedMarker(null)}
          report={selectedMarker}
          safetyStatus={latestSafetyStatus}
          timeObserved={formatTimeReported(latestTimeReported)}
          photoUrls={latestPhotoUrl}
          videoUrls={latestVideoUrl}
          activeTab={activeTab} 
        />
      )}
    </div>
    </LoadingWrapper>
  );
};

export default SleuthNetReportsMap;  