// Report Name Mapping
export const reportNameMapping = {
    uxo: 'UXOs',
    militaryActivity: 'Military Activity',
    naturalDisaster: 'Natural Disaster',
    structureHazard: 'Structure Hazard',
    terroristActivity: 'Terrorist Activity',
    criminalActivity: 'Criminal Activity',
    warCrime: 'War Crimes',
    wildlife: 'Wildlife Hazard',
    resourceScarcity: 'Resource Scarcity',
    //nasa: 'NASA EONet Events',
    publicHealthHazard: 'Public Health Hazard',
    cyberThreat: 'Cyber Threat',
    transportationHazard: 'Transportation Hazard',
    environmentalHazard: 'Environmental Hazard',
    humanTrafficking: 'Human Trafficking',
    boloReport: 'Missing Person',
  };
  
  // Safety Status Mapping
  export const safetyStatusMapping = {
    unknown: 'Safety conditions are currently unknown',
    safe: 'Area is safe',
    notSafe: 'Area is not safe',
  };
  
  export const safetyStatusMappingSubmit = {
  'Safety conditions are currently unknown': 'unknown',
  'Area is safe': 'safe',
  'Area is not safe': 'notSafe',
};


  
  // Missing Status Mapping
  export const missingStatusMapping = {
    missing: 'Person is missing',
    deceased: 'Person is deceased',
    found: 'Person is found',
    coldCase: 'Cold case',
  };
  

// Priority Levels Mapping
export const priorityMapping = {
  high: 'High Priority',
  medium: 'Medium Priority',
  low: 'Low Priority',
};

// Group ages into age categories
export const groupAges = (reports) => {
  const ageGroups = {
    '0-5 years': 0,
    '5-10 years': 0,
    '10-20 years': 0,
    '20-30 years': 0,
    '30-40 years': 0,
    '40-50 years': 0,
    '50-60 years': 0,
    '70+ years': 0,
  };

  reports.forEach((report) => {
    const age = parseInt(report.age, 10);
    if (!isNaN(age)) {
      if (age >= 0 && age <= 5) ageGroups['0-5 years']++;
      else if (age > 5 && age <= 10) ageGroups['5-10 years']++;
      else if (age > 10 && age <= 20) ageGroups['10-20 years']++;
      else if (age > 20 && age <= 30) ageGroups['20-30 years']++;
      else if (age > 30 && age <= 40) ageGroups['30-40 years']++;
      else if (age > 40 && age <= 50) ageGroups['40-50 years']++;
      else if (age > 50 && age <= 60) ageGroups['50-60 years']++;
      else if (age > 70) ageGroups['70+ years']++;
    }
  });

  return ageGroups;
};