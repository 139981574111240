import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, GeoPoint, writeBatch, getDocs, query, getDoc, where, updateDoc, doc, addDoc } from 'firebase/firestore';
import { firestore, storage, auth } from '../../firebase';
import { Row, Col, Button, Input, CardBody, CardTitle, CardText, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import DownloadButton from '../components/DownloadButton';
import VideoPlayer from '../components/VideoPlayer';
import PhotoViewer from '../components/PhotoViewer';
import DocumentDownloader from '../components/DocumentDownloader';
import { CsvDataGenerator } from '../components/CsvDataGenerator';
import { useAuthState } from "react-firebase-hooks/auth";
import csvIcon from '../../assets/img/csv.png';
import pdfIcon from '../../assets/img/pdf.png';
import { reportNameMapping, missingStatusMapping } from 'utils/mapping';
import { useMembership } from 'hooks/useMembership';
import { getMissingStatusStyle } from 'utils/chartDataFormatters';
import LoadingWrapper from 'views/components/LoadingWrapper';
import { shortenURL, isValidURL } from '../../utils/urlHelpers';
import useFileUploader from "hooks/useFileUploader";


function ReportDetailsBolo() {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [userMembershipLevel, setUserMembershipLevel] = useState(null);
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mostRecentUpdate, setMostRecentUpdate] = useState(null);
  const [note, setNote] = useState('');
  const [missingStatus, setMissingStatus] = useState('Person is missing');
  const [newMissingStatus, setNewMissingStatus] = useState('');
  const { uploadFiles } = useFileUploader(storage);
  const mapRef = useRef(null);
  const [supportingUrl, setSupportingUrl] = useState('');
  const [mediaFiles, setMediaFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const referrer = localStorage.getItem('referrer') || '/admin/dashboard';
  const [photoUrls, setPhotoUrls] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [documentUrls, setDocumentUrls] = useState([]);
  const [locationModal, setLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null); 
  const [csvData, setCsvData] = useState([]);
  const mapContainerRef = useRef(null);
  const { isSuperUser, handleRestrictedAction, modal, toggleModal, modalContent, setModalContent} = useMembership(userMembershipLevel || 'basic');

  
  
  const toggleLocationModal = () => setLocationModal(!locationModal);

  useEffect(() => {
    if (user) {
      const fetchUserMembership = async () => {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const membership = userDoc.data().membershipLevel || "basic";
          setUserMembershipLevel(membership);
        }
      };
      fetchUserMembership();
    }
  }, [user]);
  

  const handleDownloadRestriction = () => {
    handleRestrictedAction('Downloading CSV or PDF files is available only for premium users.');
  };

  const handleNoteSubmission = () => {
    handleRestrictedAction('Submitting notes is restricted to premium users.');
  };
  const handleMissingStatusRestriction = () => {
    handleRestrictedAction('Updating missing status is restricted to premium users.');
  };

  
  const submitNote = async () => {
    if (!isSuperUser) {
      handleNoteSubmission();
      return;
    }
  
    setAttemptedSubmit(true);
  
    if (note.trim() === '') {
      setModalContent('Please provide a comment before submitting.');
      toggleModal();
      return;
    }
  
    try {
      if (!report || !report.id) {
        setModalContent('Report ID is missing. Cannot submit comment.');
        toggleModal();
        return;
      }
  
      setModalContent('Uploading files... 0% complete.');
      toggleModal();
  
      const allFiles = [...mediaFiles, ...documentFiles];
  
      const updateProgress = (progress) => {
        setModalContent(`Uploading files... ${progress}% complete.`);
      };
  
      const uploadedFiles = allFiles.length > 0
        ? await uploadFiles(allFiles, `${report.reportType}Report/${report.id}`, updateProgress)
        : [];
  
      const photoUrls = uploadedFiles
        .filter(({ name }) => /\.(jpeg|jpg|png|webp)$/i.test(name))
        .map(({ url }) => url);
  
      const videoUrls = uploadedFiles
        .filter(({ name }) => /\.(mp4|mov|avi|mkv)$/i.test(name))
        .map(({ url }) => url);
  
      const documentStrings = uploadedFiles
        .filter(({ name }) => /\.(pdf|doc|docx|xls|xlsx|ppt|pptx|txt|csv)$/i.test(name))
        .map(({ url }) => url);
  
      const commentData = {
        submittedUserId: user.uid,
        comment: note.trim(),
        timeReported: new Date(),
        archived: false,
        supportingUrl: supportingUrl || null,
        supportingPhotoUrl: photoUrls,
        supportingVideoUrl: videoUrls,
        supportingDocuments: documentStrings,
        latitude: selectedLocation?.lat || null,
        longitude: selectedLocation?.lng || null,
        location: selectedLocation
          ? new GeoPoint(selectedLocation.lat, selectedLocation.lng)
          : null,
        city: selectedLocation?.city || '',
        state: selectedLocation?.state || '',
        country: selectedLocation?.country || '',
      };
  
      const batch = writeBatch(firestore);
      const commentRef = doc(collection(firestore, 'boloReport', report.id, 'Comment'));
      batch.set(commentRef, commentData);
  
      await batch.commit();
  
      setNote('');
      setSupportingUrl('');
      setMediaFiles([]);
      setDocumentFiles([]);
      setSelectedLocation(null);
      setModalContent('Your comment and materials have been successfully submitted!');
      toggleModal();
    } catch (error) {
      console.error('Error submitting comment and materials:', error);
      setModalContent('Failed to submit comment and materials.');
      toggleModal();
    } finally {
      setAttemptedSubmit(false);
    }
  };
  
  
  
  const openLocationModal = () => {
    toggleLocationModal();
    setTimeout(initializeMap, 500); 
  };

  const initializeMap = () => {
    if (!mapContainerRef.current) return;
    const google = window.google;
    const map = new google.maps.Map(mapContainerRef.current, {
      center: { lat: 37.7749, lng: -122.4194 },
      zoom: 5,
    });
    const marker = new google.maps.Marker({ map, draggable: true });
    google.maps.event.addListener(map, 'click', (event) => {
      marker.setPosition(event.latLng);
      fetchLocationData(event.latLng.lat(), event.latLng.lng());
    });
  };

  const fetchLocationData = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const addressComponents = results[0].address_components;
        const getAddressComponent = (type) => addressComponents.find(comp => comp.types.includes(type))?.long_name || '';
        const city = getAddressComponent('locality');
        const state = getAddressComponent('administrative_area_level_1');
        const country = getAddressComponent('country');
        setSelectedLocation({ lat, lng, city, state, country });
      }
    });
  };

  const getField = (updateField, reportField) => {
    return updateField !== undefined && updateField !== null && updateField !== '' ? updateField : reportField;
  };

  const formatTimeReported = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== 'function') return 'Invalid date';
    const date = timestamp.toDate();
    return format(date, 'MMMM d, yyyy hh:mm a', { locale: enUS });
  };
  useEffect(() => {
    if (user) {
      const fetchUserMembership = async () => {
        const userDocRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserMembershipLevel(userDoc.data().membershipLevel || "basic");
        }
      };
      fetchUserMembership();
    }
  }, [user]);
  
  
  useEffect(() => {
    async function fetchReport() {
      setIsLoading(true);
  
      try {
        const reportQuery = query(collection(firestore, "boloReport"), where("reportId", "==", reportId));
        const reportSnapshot = await getDocs(reportQuery);
  
        if (!reportSnapshot.empty) {
          const docSnap = reportSnapshot.docs[0];
          const reportData = docSnap.data();
          const reportDocId = docSnap.id;
  
          const [updatesSnapshot, commentsSnapshot] = await Promise.all([
            getDocs(collection(firestore, "boloReport", reportDocId, "Update")),
            getDocs(collection(firestore, "boloReport", reportDocId, "Comment")),
          ]);
  
          let allPhotoUrls = new Set(reportData.photoUrl || []);
          let allVideoUrls = new Set(reportData.videoUrl || []);
          let allDocumentUrls = new Set(reportData.documentUrl || []);
          let missingStatus = reportData.missingStatus || "missing";
          let mostRecentTimeReported = reportData.timeReported;
          let mostRecentUpdate = null;
  
          updatesSnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.timeReported?.toDate() > mostRecentTimeReported?.toDate()) {
              mostRecentTimeReported = data.timeReported;
              missingStatus = data.missingStatus || missingStatus;
              mostRecentUpdate = data;
            }
            allPhotoUrls = new Set([...allPhotoUrls, ...(data.photoUrl || [])]);
            allVideoUrls = new Set([...allVideoUrls, ...(data.videoUrl || [])]);
            allDocumentUrls = new Set([...allDocumentUrls, ...(data.documentUrl || [])]);
          });
  
          commentsSnapshot.forEach((doc) => {
            const data = doc.data();
            allPhotoUrls = new Set([...allPhotoUrls, ...(data.supportingPhotoUrl || [])]);
            allVideoUrls = new Set([...allVideoUrls, ...(data.supportingVideoUrl || [])]);
            allDocumentUrls = new Set([...allDocumentUrls, ...(data.supportingDocuments || [])]);
          });
  
          setMissingStatus(missingStatusMapping[missingStatus] || "Person is missing");
          setMostRecentUpdate(mostRecentUpdate);
          setPhotoUrls([...allPhotoUrls]);
          setVideoUrls([...allVideoUrls]);
          setDocumentUrls([...allDocumentUrls]);
          setReport({ ...reportData, id: reportDocId });
        } else {
          setMissingStatus("No report found.");
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  
    fetchReport();
  }, [reportId]);
  
  useEffect(() => {
    const generateCsvData = async () => {
      if (!report) {
        console.warn('No report data available for CSV generation.');
        return;
      }
  
      try {
        const data = await CsvDataGenerator({
          reports: [report],
          isBoloReport: true,
          isDetailedReport: true, 
        });
        setCsvData(data);
      } catch (error) {
      }
    };
  
    generateCsvData();
  }, [report, photoUrls, videoUrls, documentUrls]);


  useEffect(() => {
    if (report && !isLoading) {
      const google = window.google;
      const latLng = new google.maps.LatLng(report.latitude, report.longitude);

      const mapOptions = {
        zoom: 5,
        center: latLng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };

      const map = new google.maps.Map(mapRef.current, mapOptions);

      new google.maps.Marker({
        position: latLng,
        map: map,
        title: report.city,
      });
    }
  }, [report, isLoading]);

  const updateMissingStatus = async () => {
    
    if (!isSuperUser) {
      handleMissingStatusRestriction();
      return;
    }
    if (!report || !report.id || newMissingStatus === '') {
      setModalContent('Report or Report ID is missing. Please select a valid missing status.');
      toggleModal();
      return;
    }

    const mappedStatus = Object.keys(missingStatusMapping).find(
      key => missingStatusMapping[key] === newMissingStatus
    );

    if (!mappedStatus) {
      setModalContent('Please select a valid missing status.');
      toggleModal();
      return;
    }

    try {
      const reportRef = doc(firestore, 'boloReport', report.id);
      await updateDoc(reportRef, { updatedAt: new Date() });

      const statusUpdateRef = collection(firestore, 'boloReport', report.id, 'StatusUpdate');
      await addDoc(statusUpdateRef, {
        timeReported: new Date(),
        submittedUserId: user.uid,
        missingStatus: mappedStatus
      });

      setMissingStatus(newMissingStatus);
      setNewMissingStatus('');
      setModalContent('Your missing status update has been successfully submitted!');
      toggleModal();
    } catch (error) {
      setModalContent('Failed to update the missing status.');
      toggleModal();
    }
  };

  const handleFileSelection = (setter, existingFiles) => (event) => {
    const newFiles = Array.from(event.target.files);
    setter([...existingFiles, ...newFiles]);
  };

  const handleFileRemoval = (setter, index) => {
    setter(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  if (isLoading) {
    return <LoadingWrapper isLoading={isLoading} />;
  }


  if (!report) {
    return <div>No report found.</div>;
  }

  const displayData = (data, defaultMessage = 'No information provided') => data || defaultMessage;

  return (
    <div>
    {/* Informational Banner */}
            {!isSuperUser && (
              <div className="membership-banner">
                <strong>Your current membership level: {userMembershipLevel}</strong>. To access full functionality, please{" "}
                <a
                  href="https://sleuthnet.com/account/billing"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#2196F3", textDecoration: "underline" }}
                >
                  upgrade your profile
                </a>
              </div>
            )}

        <Row  className="align-items-center justify-content-between"
            style={{ marginTop: isSuperUser ? '70px' : '10px' }}>
        <Col xs="auto">
          <button className="btn btn-info" onClick={() => navigate(referrer)} style={{ marginLeft: '20px' }}>
            Go Back
          </button>
        </Col>
        <Col xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '12px', fontSize: '12px' }}>DOWNLOAD</span>
          {isSuperUser ? (
            <DownloadButton
              targetId="report-details-container"
              data={csvData}
              fileName={`${report.reportType}_${report.reportId}`}
              isDataLoaded={!isLoading}
            />
          ) : (
            <div className="download-restriction" onClick={handleDownloadRestriction}>
              <img src={csvIcon} alt="CSV" width="24" height="24" />
              <span>CSV</span>
              <img src={pdfIcon} alt="PDF" width="24" height="24" />
              <span>PDF</span>
            </div>
          )}
        </Col>
      </Row>

      <div id="report-details-container" className="report-details-container">
        <Row>
          <Col lg="6" md="12" style={{ paddingRight: '15px' }}>
            <CardBody>
              <CardText style={{ marginTop: '10px', fontSize: '12px' }}>
                <span style={{ textTransform: 'uppercase' }}>Last Update: </span>
                {mostRecentUpdate?.timeReported
                  ? formatTimeReported(mostRecentUpdate.timeReported)
                  : formatTimeReported(report.timeReported)}
              </CardText>
              <CardTitle style={{ marginTop: '10px' }}>
                <span className='label-text-subtitle'>
                  {reportNameMapping[report.reportType] || displayData(report.reportType)}
                </span>
              </CardTitle>
              <CardText style={{ marginTop: '10px' }}>
                <span className="label-text">Location: </span> {`${displayData(report.city)}, ${displayData(report.country)}`}
              </CardText>
              <CardText>
                <span className='label-text'> Date / Time Observed: </span> {formatTimeReported(report.timeReported)}
              </CardText>
              <CardText style={{ marginTop: '10px' }}>
                <span className="label-text"> Reporting App: </span> {displayData(report.reportingApp)}
              </CardText>
              <CardText><span className='label-text'> Person Name: </span> {displayData(report.firstName)} {displayData(report.lastName)}</CardText>
              <CardText><span className='label-text'> Gender: </span> {displayData(report.gender)}</CardText>
              <CardText><span className='label-text'> Ethnicity: </span> {displayData(report.raceEthnicity)}</CardText>
              <CardText><span className='label-text'> Age: </span> {displayData(report.age)}</CardText>
              {report.reportingApp === 'Interpol' && (
                <CardText>
                  <span className='label-text'>Additional Comments: </span>
                  {report.description && report.description.trim() ? (
                    displayData(report.description)
                  ) : (
                    <>
                      {`Hair Color: ${Array.isArray(report.hairColor) && report.hairColor.length > 0 ? report.hairColor.join(', ') : "No information provided"}. `}
                      {`Eye Color: ${Array.isArray(report.eyeColor) && report.eyeColor.length > 0 ? report.eyeColor.join(', ') : "No information provided"}. `}
                      {`Weight: ${report.weight || "No information provided"}. `}
                      {`Height: ${report.height || "No information provided"}. `}
                      {`Countries likely visited: ${report.countriesVisited || "No information provided"}.`}
                    </>
                  )}
                </CardText>
              )}
              {['NamUs'].includes(report.reportingApp) && (
                <CardText><span className='label-text'> Additional Comments:</span> {displayData(report.description)}</CardText>
                )}
              <CardText><span className='label-text'> Contact Method: </span> {displayData(report.contactNumber)}</CardText>
              <CardText>
                <span className="label-text">Case Link: </span>
                {(() => {
                  if (report.originalSocialMediaLink && isValidURL(report.originalSocialMediaLink)) {
                    const shortenedUrl = shortenURL(report.originalSocialMediaLink);
                    return (
                      <a
                        href={report.originalSocialMediaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#007bff', textDecoration: 'underline' }}
                      >
                        {shortenedUrl}
                      </a>
                    );
                  } else {
                    return <span>No valid link available</span>;
                  }
                })()}
              </CardText>
            </CardBody>
          </Col>

          <Col lg="6" md="12" style={{ marginTop: '60px' }}>
            <div ref={mapRef} className="report-map" style={{ height: '300px' }}></div>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
          <ModalHeader className="modal-general modal-header" toggle={toggleModal}>
            Upgrade Your Membership
          </ModalHeader>
          <ModalBody className="modal-general modal-body">{modalContent}</ModalBody>
        </Modal>
        <Row style={{ marginTop: '30px' }}>
          <Col lg="6" md="12">
            <Row style={{ marginTop: '16px' }}>
              <Col lg="12" md="12">
                <CardTitle className='label-text-subtitle'>Provide Notes</CardTitle>
                <textarea
                  className="notes-input"
                  placeholder="Provide notes and details for the community regarding this specific report...."
                  value={note}
                  onChange={(e) => { setNote(e.target.value); setAttemptedSubmit(false); }}
                  style={{ borderColor: attemptedSubmit && note.trim() === '' ? 'red' : '' }}
                />
                <Input
                  type="text"
                  className="notes-input-url"
                  value={supportingUrl}
                  onChange={(e) => setSupportingUrl(e.target.value)}
                  placeholder="Add URL or Social Media Link"
                />
                
                <Row style={{ marginTop: '10px', marginLeft: '0px' }}>
                    <Button className="btn btn-outline-default">
                      <label>
                        Add Photo/Video
                        <Input
                          type="file"
                          id="mediaInput"
                          multiple
                          onChange={handleFileSelection(setMediaFiles, mediaFiles)}
                          style={{ display: 'none' }}
                          accept="image/*,video/*"
                        />
                      </label>
                    </Button>
                    <Button className="btn btn-outline-default" style={{ marginLeft: '10px' }}>
                      <label>
                        Add Document
                        <Input
                          type="file"
                          id="documentInput"
                          multiple
                          onChange={handleFileSelection(setDocumentFiles, documentFiles)}
                          style={{ display: 'none' }}
                          accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
                        />
                      </label>
                    </Button>

                    <Button className="btn btn-outline-default" style={{ marginLeft: '10px' }} onClick={openLocationModal}>
                      <label> 
                        Select Location
                      </label>
                    </Button>
              </Row>

                  {selectedLocation && (
                    <p style={{ marginTop: '10px', marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                      <strong>Selected Location: </strong>  {selectedLocation.city}, {selectedLocation.state}, {selectedLocation.country}
                      <i 
                        className="nc-icon nc-simple-remove" 
                        style={{ marginLeft: '10px', cursor: 'pointer' }} 
                        onClick={() => setSelectedLocation(null)}
                      />
                    </p>
                  )}

                  <div style={{ marginTop: '10px', marginLeft: '15px' }}>
                    {mediaFiles.length > 0 && (
                      <div>
                        <strong>Uploaded Photos/Videos:</strong>
                        {mediaFiles.map((file, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                            <span>{file.name}</span>
                            <i
                              className="nc-icon nc-simple-remove"
                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                              onClick={() => handleFileRemoval(setMediaFiles, index)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {documentFiles.length > 0 && (
                      <div style={{ marginTop: '10px' }}>
                        <strong>Uploaded Documents:</strong>
                        {documentFiles.map((file, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                            <span>{file.name}</span>
                            <i
                              className="nc-icon nc-simple-remove"
                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                              onClick={() => handleFileRemoval(setDocumentFiles, index)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                    <Modal isOpen={locationModal} toggle={toggleLocationModal} className="modal-general">
                      <ModalHeader toggle={toggleLocationModal}>Select Location</ModalHeader>
                      <ModalBody>
                        <div ref={mapContainerRef} style={{ height: '400px', width: '100%' }}></div>
                        {selectedLocation && (
                          <p style={{ marginTop: '10px' }}>
                            <strong>Selected Location:</strong> {selectedLocation.city}, {selectedLocation.state}, {selectedLocation.country}
                          </p>
                        )}
                      </ModalBody>
                    </Modal>
                          
                  <div className="button-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start' }}>
                  <button className='btn btn-info' onClick={submitNote}>Add Notes</button>
                </div>
              </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
              <ModalHeader className=".modal-general .modal-header" toggle={toggleModal}>Note Submission</ModalHeader>
              <ModalBody className=".modal-general .modal-body">{modalContent}</ModalBody>
            </Modal>
            <div>
              <CardText className='label-text-subtitle' style={{ marginTop: '50px' }}>Community Notes </CardText>
              <CardText>This section is coming soon ... </CardText>
            </div>
          </Col>

          <Col lg="6" md="12" style={{ marginTop: '15px' }}>
            <div>
              <CardTitle className='label-text-subtitle'>Current Missing Status:</CardTitle>
              <div className="safety-status-box" style={getMissingStatusStyle(missingStatus)}>
                <CardText className="text-uppercase">{missingStatus}</CardText>
              </div>
              <CardTitle style={{ marginTop: '20px' }} className='label-text-subtitle'>Update Missing Status:</CardTitle>
              <Input
                style={{ marginTop: '20px' }}
                type="select"
                value={newMissingStatus}
                onChange={e => setNewMissingStatus(e.target.value)}
              >
                <option value="">Select missing status</option>
                {Object.values(missingStatusMapping).map(status => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </Input>

              <button style={{ marginTop: '20px' }} className='btn btn-info' onClick={updateMissingStatus}>Update Status</button>

              <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
                <ModalHeader className=".modal-general .modal-header" toggle={toggleModal}>Update Confirmation</ModalHeader>
                <ModalBody className=".modal-general .modal-body">{modalContent}</ModalBody>
              </Modal>
            </div>

            <Col style={{ marginTop: '20px' }}>
              <CardTitle><span className='label-text-title'>Photos</span></CardTitle>
              {photoUrls && photoUrls.length > 0 ? (
                <PhotoViewer photoUrls={photoUrls} />
              ) : (
                <CardText>No photos attached to the report.</CardText>
              )}
            </Col>

            <Col style={{ marginTop: '20px' }}>
              <CardTitle><span className='label-text-title'>Videos</span></CardTitle>
              {videoUrls && videoUrls.length > 0 ? (
                <VideoPlayer videoUrl={videoUrls} />
              ) : (
                <CardText>No videos attached to the report.</CardText>
              )}
            </Col>

            <Col style={{ marginTop: '20px', marginBottom: '20px' }}>
              <CardTitle><span className='label-text-title'>Documents</span></CardTitle>
              <DocumentDownloader documentUrls={documentUrls} />
            </Col>

          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ReportDetailsBolo;
