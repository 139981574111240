import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Nav, NavLink } from 'reactstrap';
import Logo from 'assets/img/sleuthnet/Logo.png';
import { FaBars } from 'react-icons/fa'; 
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; 



function Sidebar() {
  const [userName, setUserName] = useState('');
  const [user, setUser] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false); 
  const [isSuperUser, setIsSuperUser] = useState(false); 
  const auth = getAuth();

  useEffect(() => {
    const fetchUserDetails = async (currentUser) => {
      if (!currentUser) return;
      try {
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsSuperUser(userData.membershipLevel === 'superUser');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setUserName(currentUser.displayName || 'Profile page');
        fetchUserDetails(currentUser);
      }
    });

    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsMobileView(true);
        setIsSidebarOpen(false);
      } else {
        setIsMobileView(false);
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [auth]);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); 
  };

  const redirectToProfileOrLogin = () => {
    if (user) {
      window.location.href = 'https://sleuthnet.com/account';
    } else {
      window.location.href = 'https://sleuthnet.com';
    }
  };

  const CustomNavLink = ({ to, children, state, extraStyle = {} }) => (
    <NavLink
      tag={Link}
      to={to}
      state={state}
      className="d-flex justify-content-between align-items-center"
      style={{
        marginLeft: '62px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        textTransform: 'uppercase',
        padding: '0.5px 0',
        ...extraStyle,
      }}
    >
      <span>{children}</span>
    </NavLink>
  );

  return (
    <>
      {isMobileView && (
        <div style={{backgroundColor:'#f8f4ec'}} onClick={toggleSidebar}>
          <FaBars size={25} color="grey" style={{ cursor: 'pointer', marginLeft: '15px', marginTop: '10px' }} />
        </div>
      )}

      <div
        className="sidebar"
        style={{
          transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)', 
          transition: 'transform 0.3s ease', 
          position: 'fixed', 
          top: 0,
          left: 0,
          height: '100%',
          backgroundColor: '#343a40',
          zIndex: 1000, 
        }}
      >
        <div
          className="logo"
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '20px',
            paddingBottom: '0',
            borderBottom: 'none',
          }}
        >
          <img src={Logo} width={'60px'} alt="Sleuth Net Pro" style={{ marginRight: '10px' }} />
          <div>
            <a
              href="https://sleuthnet.com/"
              className="simple-text logo-normal"
              style={{
                textAlign: 'left',
                fontSize: '14px',
                paddingBottom: '0',
                marginBottom: '0',
              }}
            >
              Sleuth Net  {isSuperUser && <b>PRO</b>}
            </a>
            <a 
              href="https://sleuthnet.com/"
              className="simple-text logo-normal"
              style={{
                textAlign: 'left',
                fontSize: '14px',
                textTransform: 'capitalize',
                paddingTop: '0',
                marginTop: '0',
                borderBottom: 'none',
                fontFamily: 'Montserrat',
                letterSpacing: '0.05em',
              }}
            >
              Analytics Platform
            </a>
          </div>
        </div>

        <div className="sidebar-wrapper" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Nav vertical style={{ marginRight: '15px', marginLeft: '-10px', border: 'none' }}>
            <li style={{ marginTop: '25px', border: 'none' }}>
              <CustomNavLink to="/admin/dashboard">Home</CustomNavLink>
            </li>
            {[
              { to: '/admin/explore-reports', label: 'Ukraine/ Russia', state: { country: ['Ukraine', 'Russia'] } },
              { to: '/admin/explore-reports', label: 'Israel/ Palestine', state: { country: ['Israel', 'Palestine'] } },
              { to: '/admin/explore-reports', label: 'Violent Extremists', state: { reportType: ['Terrorist Activity'] } },
              { to: '/admin/explore-reports/bolo', label: 'Missing People' },
              { to: '/admin/coming-soon', label: 'Climate Change' },
              { to: '/admin/coming-soon', label: 'Federal Elections' },
              { to: '/admin/explore-reports', label: 'Explore Reports' },
            ].map((item, index) => (
              <li key={index} style={{ marginTop: '1px', border: 'none' }}>
                <CustomNavLink to={item.to} state={item.state}>
                  {item.label}
                </CustomNavLink>
              </li>
            ))}

            <li style={{ marginTop: '1px', border: 'none' }}>
              <CustomNavLink to="/admin/map">Full Map</CustomNavLink>
            </li>
            <li style={{ marginTop: '50px', marginLeft: '15px', border: 'none' }}>
              <NavLink
                onClick={redirectToProfileOrLogin}
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Montserrat',
                  fontSize: '11px',
                  textTransform: 'uppercase',
                  padding: '0.5px 0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {user && user.photoURL && !imageError ? (
                  <img
                    src={user.photoURL}
                    alt="User Profile"
                    onError={() => setImageError(true)}
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      marginRight: '10px',
                    }}
                  />
                ) : (
                  <i className="nc-icon nc-single-02" />
                )}
                <span
                  style={{
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {userName || 'Profile page'}
                </span>
              </NavLink>
            </li>
            {[
              { to: '/admin/my-investigations', label: 'My investigations' },
              { to: '/admin/coming-soon', label: 'Team investigations' },
            ].map((item, index) => (
              <li key={index} style={{ marginTop: '1px', marginLeft: '15px', border: 'none' }}>
                <CustomNavLink to={item.to} state={{ title: item.label }}>
                  {item.label}
                </CustomNavLink>
              </li>
            ))}
            <li style={{ border: 'none' }}>
              <CustomNavLink
                to="/admin/contact-us"
                extraStyle={{
                  marginBottom: '0px',
                  marginTop: '70px',
                  marginLeft: '62px',
                  padding: '0.5px 0',
                }}
              >
                Submit Feedback
              </CustomNavLink>
            </li>
          </Nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
